import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'

const PermitsMembers = ({ showPopUp, closeModal, data }) => {
    const [showModal, setShowModal] = useState(showPopUp);
    const [usersList, setUsersList] = useState([])
    useEffect(() => {
        setShowModal(showPopUp);
    }, [showPopUp]);

        if (data) {
            usersList.push(...data.leaders);
        } else {
            console.log("No Users")
        }


    return (
        <Fragment>
            <Transition appear show={showModal} as={Fragment}>
                <Dialog as='div' className="relative z-10" onClose={() => { closeModal(); setUsersList([]) }}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900 pb-4"
                                    >
                                        Members Details
                                    </Dialog.Title>
                                    <select className=' p-4 rounded-lg border border-black w-1/2'>
                                        {
                                            usersList.map((user, index) => (
                                                <option className=' uppercase' key={user.id} value={user.fullName}>{user.fullName + " || " + user.position}</option>
                                            ))
                                        }
                                    </select>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </Fragment>
    )
}

export default PermitsMembers