import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import Header from './Layouts/Header';
import CompaniesList from './Pages/Companies/Companies';
import Dashboard from './Pages/Dashboard/Dasboard';
import EventsList from './Pages/Events/Events';
import AddFee from './Pages/Fees/AddFee';
import FeesList from './Pages/Fees/FeesList';
import Login from './Pages/Login/Login';
import DailyPermitsList from './Pages/Permits/DailyPermitsList';
import YearlyPermitsList from './Pages/Permits/YearlyPermitsList';
import UsersList from './Pages/Users/UsersList';
import FujairahCities from './Pages/Website/FujairahCities';
import FujairahEvents from './Pages/Website/FujairahEvents';
import FujairahGallery from './Pages/Website/FujairahGallery';
import FujairahTrails from './Pages/Website/FujairahTrails';
import MainWebsite from './Pages/Website/MainWebsite';
import StaffsList from './Pages/Website/StaffsList';
import Callback from './Pages/callback/Callback';

const App = (props) => {

    const location = useLocation();
    const token = localStorage.getItem("token")

    return (
        <React.Fragment>
            {location.pathname !== '/login' && <Header />}
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Protected token={token}><Dashboard /></Protected>} />
                <Route path="/daily_permits_list" element={<Protected token={token}><DailyPermitsList /></Protected>} />
                <Route path="/yearly_permits_list" element={<Protected token={token}><YearlyPermitsList /></Protected>} />
                <Route path="/companies_list" element={<Protected token={token}><CompaniesList /></Protected>} />
                <Route path="/events_list" element={<Protected token={token}><EventsList /></Protected>} />
                <Route path="/fees_list" element={<Protected token={token}><FeesList /></Protected>} />
                <Route path="/add_fee" element={<Protected token={token}><AddFee /></Protected>} />
                <Route path="/main_website" element={<Protected token={token}><MainWebsite /></Protected>} />
                <Route path="/local_events" element={<Protected token={token}><FujairahEvents /></Protected>} />
                <Route path="/local_cities" element={<Protected token={token}><FujairahCities /></Protected>} />
                <Route path="/local_trails" element={<Protected token={token}><FujairahTrails /></Protected>} />
                <Route path="/local_gallery" element={<Protected token={token}><FujairahGallery /></Protected>} />
                <Route path="/registred_users" element={<Protected token={token}><UsersList /></Protected>} />
                <Route path="/staffs_list" element={<Protected token={token}><StaffsList /></Protected>} />
                <Route path="/callback" element={<Callback />} />
            </Routes>
        </React.Fragment>
    );
};
export default App;


export const Protected = ({ token, children }) => {

    const [validToken, setValidToken] = useState(false)
    const decoded = token ? jwt_decode(token) : ""
    delete decoded.email
    delete decoded.user_id
    let currentDate = new Date();
    useEffect(() => {
        if (decoded.exp * 1000 < currentDate.getTime()) {
            setValidToken(false)
        } else {
            setValidToken(true)
        }
    }, [])
    

    if (!token && validToken === false) {
        return <Navigate to="/login" replace />;
    }
    return children;
};