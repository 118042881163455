import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { HiInformationCircle } from 'react-icons/hi2'
import { TiCancel, TiTick } from 'react-icons/ti'
import Swal from 'sweetalert2'

const FujairahTrails = () => {

    const detailsModelRef = useRef(null);
    const createEventRef = useRef(null)

    const openDetailsModal = (event) => {
        setSelectedTrail(event)
        if (detailsModelRef.current) {
            detailsModelRef.current.showModal();
        }
    };

    const openCreateEventModal = () => {
        if (createEventRef.current) {
            createEventRef.current.showModal();
        }
    };

    const [trailsData, setTrailsData] = useState([])
    const [apiBaseUrl, setApiBaseUrl] = useState(axios.defaults.baseURL)
    const [selectedTrail, setSelectedTrail] = useState([])


    const getAllTrails = () => {
        axios.get(`trails/infos`)
            .then(res => {
                setTrailsData(res.data)
                console.log(res.data)
            })
            .catch(error => {
                console.log(error)
                Swal.fire(
                    "Error!",
                    `${error.response.data.message}`,
                    "error"
                )
            })
    }

    const closeCreateEventModal = () => {
        if (createEventRef.current) {
            createEventRef.current.close();
        }
    };

    useEffect(() => {
        getAllTrails();
        // console.log(axios.defaults.baseURL)
    }, [])

    const deleteEvent = (eventId) => {
        const token = localStorage.getItem("token")
        axios.delete(`trails/delete/${eventId}/?token=${token}`)
            .then(res => {
                console.log(res.data);
                getAllTrails()
            })
            .catch(err => {
                Swal.fire(
                    "Error!",
                    `${err.response.data.message}`,
                    "error"
                )
            })
    }

    return (
        <div className=' flex flex-col items-center w-screen py-10 space-y-10'>
            <div className=' flex flex-row w-10/12 items-center p-4 bg-[#DCC3B1] rounded-xl '>
                <div className=' w-full'>
                    <p className=' text-xl font-semibold capitalize'>Fujairah Trails List</p>
                </div>
                <div>
                    <button className='btn bg-white' onClick={() => { openCreateEventModal() }} >Create Trail</button>
                    <CreateCityModal raf={createEventRef} getData={getAllTrails} closeModal={closeCreateEventModal} />
                </div>
            </div>
            <div className="overflow-x-auto w-10/12">
                <table className="table table-zebra">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Trail Name</th>
                            <th>Trail Description</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            trailsData.map((trail) => (
                                <tr key={trail.id}>
                                    <th>{trail.id}</th>
                                    <td>{trail.name}</td>
                                    <td className=' w-6/12'>{(trail.description).slice(0, 250) + "..."}</td>
                                    <td>
                                        <div className=' flex flex-row space-x-3' >
                                            <div className='tooltip hover:cursor-pointer' data-tip="City Details" onClick={() => { openDetailsModal(trail) }}>
                                                <HiInformationCircle size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                                                <DetailsModel raf={detailsModelRef} data={selectedTrail} url={apiBaseUrl.replace('/api/v1', '')} />
                                            </div>
                                            <div className='tooltip hover:cursor-pointer' data-tip="Delete City" onClick={() => { deleteEvent(trail.id) }}>
                                                <TiCancel size={24} color='#8E4F4F' className=' bg-[#8E4F4F69] rounded-md' />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default FujairahTrails


export const DetailsModel = ({ url, data, raf }) => {

    return (
        <dialog className="modal" ref={raf}>
            <form method="dialog" className="modal-box w-11/12 max-w-5xl items-start">
                <div className=' grid grid-cols-2 gap-5'>
                    <div className='w-full flex flex-col space-y-2'>
                        {
                            data.bodyPicture !== null ?
                                <img src={url + data.bodyPicture} className=' object-cover w-full h-full' alt="" />
                                :
                                null
                        }
                        <label htmlFor="bodyPicture" className=' font-semibold text-lg'>Body Picture</label>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        {
                            data.heroPicture !== null ?
                                <img src={url + data.heroPicture} className=' object-cover w-full h-full' alt="" />
                                :
                                null
                        }
                        <label htmlFor="heroPicture" className=' font-semibold text-lg'>Hero Picture</label>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="outsidePicture" className=' font-semibold text-lg'>PDF File</label>
                        {
                            data.pdfFile !== null ?
                                <a href={url + data.pdfFile} target='_blank' className=' btn object-cover w-full ' alt="" >View PDF</a>
                                :
                                null
                        }
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventName" className=' font-semibold text-lg'>Trail Name</label>
                        <div id='eventName' type="text" className='p-3 bg-[#A55B2730] rounded-md text-left px-7'>
                            {data.name}
                        </div>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventDescription" className=' font-semibold text-lg'>Trail Description</label>
                        <div id='eventDescription' className='p-3 bg-[#A55B2730] rounded-md text-left px-7'>
                            {data.description}
                        </div>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventName" className=' font-semibold text-lg'>Trail Difficulty</label>
                        <div id='eventName' type="text" className='p-3 bg-[#A55B2730] rounded-md text-left px-7'>
                            {
                                data.difficulty === 1 ? "Very Easy" :
                                    data.difficulty === 2 ? "Easy" :
                                        data.difficulty === 3 ? "Medium" :
                                            data.difficulty === 4 ? "Hard" :
                                                data.difficulty === 5 ? "Extreme" :
                                                    "none"
                            }

                        </div>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventDescription" className=' font-semibold text-lg'>Trail Time</label>
                        <div id='eventDescription' className='p-3 bg-[#A55B2730] rounded-md text-left px-7'>
                            {data.timeToFinish + " min"}
                        </div>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventDescription" className=' font-semibold text-lg'>Trail Distance</label>
                        <div id='eventDescription' className='p-3 bg-[#A55B2730] rounded-md text-left px-7'>
                            {data.distance + " KM"}
                        </div>
                    </div>
                </div>
                <div className="modal-action">
                    {/* if there is a button, it will close the modal */}
                    <button className="btn bg-[#8E4F4F69] text-[#8E4F4F]">Close</button>
                </div>
            </form>
        </dialog>
    )
}

export const CreateCityModal = ({ raf, getData, closeModal }) => {

    const [Name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [bodyPictureLink, setBodyPictureLink] = useState("")
    const [heroPicureLink, setHeroPicureLink] = useState("")
    const [shortDescription, setShortDescription] = useState('')
    const [pdfFile, setPdfFile] = useState(null)
    const [pdfFileLink, setPdfFileLink] = useState("")
    const [bodyPicture, setBodyPicture] = useState(null)
    const [heroPicture, setHeroPicture] = useState(null)
    const [distance, setDistance] = useState(0)
    const [difficulty, setDifficulty] = useState('')
    const [timeToFinish, setTimeToFinish] = useState(0)
    const [NameAr, setNameAr] = useState('')
    const [descriptionAr, setDescriptionAr] = useState('')
    const [shortDescriptionAr, setShortDescriptionAr] = useState('')
    const [trailLocation, setTrailLocation] = useState("")
    const [citiesData, setCitiesData] = useState([])
    const [cityId, setCityId] = useState("")

    const updateData = (e, state) => {
        state(e.target.value)
    }

    const uploadVideo = async (file, setLinkState) => {
        const formData = new FormData();
        formData.append('userFile', file); // Use the same field name as in your Express route

        try {
            const response = await axios.post('upload/upload_file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
                },
            });
            setLinkState(response.data)

            console.log('File uploaded successfully:', response.data);
        } catch (error) {
            Swal.fire(
                "Error!",
                `${error}`,
                "error"
            )
        }
    }

    const assignTrailToCity = (trailId) => {
        axios.post(`trails/cities/${trailId}`, { cityId: cityId })
            .then(res => {
                console.log(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const createEvent = () => {
        const datas = {
            bodyPicture: bodyPictureLink,
            heroPicture: heroPicureLink,
            name: Name,
            description: description,
            nameAr: NameAr,
            descriptionAr: descriptionAr,
            pdfFile: pdfFileLink,
            shortDescription: shortDescription,
            shortDescriptionAr: shortDescriptionAr,
            difficulty: difficulty,
            distance: distance,
            timeToFinish: timeToFinish,
            location: trailLocation
        }
        const token = localStorage.getItem("token")

        axios.post(`trails/create/?token=${token}`, datas)
            .then(res => {
                console.log(res.data)
                assignTrailToCity(res.data.id)
                closeModal()
                Swal.fire(
                    "Success!",
                    "City successfully created",
                    "success"
                )
                setBodyPictureLink("")
                setBodyPicture(null)
                setHeroPicureLink("")
                setHeroPicture(null)
                setDescription("")
                setShortDescription("")
                getData()

            })
            .catch(err => {
                Swal.fire(
                    "Error!",
                    `${err.response.data.message}`,
                    "error"
                )
            })
    }

    const handleDifficultyChange = (selectedDifficulty) => {
        // Update the difficulty state in the parent component
        setDifficulty(selectedDifficulty);
    };

    const getAllCities = () => {
        axios.get(`cities/infos`)
            .then(res => {
                setCitiesData(res.data)
                console.log(res.data)
            })
            .catch(error => {
                console.log(error)
                Swal.fire(
                    "Error!",
                    `${error.response.data.message}`,
                    "error"
                )
            })
    }

    useEffect(() => {
        getAllCities()
    }, [])


    return (
        <dialog className="modal" ref={raf}>
            <form method="dialog" className="modal-box w-11/12 max-w-5xl items-start space-y-5">
                <div className=' grid grid-cols-2 gap-5'>
                    <div className='w-full flex flex-col space-y-2'>
                        {
                            bodyPicture !== null ?
                                <img src={URL.createObjectURL(bodyPicture)} className=' object-cover w-full h-full' alt="" />
                                :
                                null
                        }
                        <label htmlFor="bodyPicture" className=' font-semibold text-lg'>Body Picture</label>
                        <input id='bodyPicture' onChange={(e) => { setBodyPicture(e.target.files[0]); uploadVideo(e.target.files[0], setBodyPictureLink) }} type="file" className="file-input file-input-bordered w-full max-w-xs" />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        {
                            heroPicture !== null ?
                                <img src={URL.createObjectURL(heroPicture)} className=' object-cover w-full h-full' alt="" />
                                :
                                null
                        }
                        <label htmlFor="heroPicture" className=' font-semibold text-lg'>Hero Picture</label>
                        <input id='heroPicture' onChange={(e) => { setHeroPicture(e.target.files[0]); uploadVideo(e.target.files[0], setHeroPicureLink) }} type="file" className="file-input file-input-bordered w-full max-w-xs" />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="heroPicture" className=' font-semibold text-lg'>Trail PDF File</label>
                        <input id='heroPicture' accept='application/pdf' onChange={(e) => { setPdfFile(e.target.files[0]); uploadVideo(e.target.files[0], setPdfFileLink) }} type="file" className="file-input file-input-bordered w-full max-w-xs" />
                    </div>
                    <div className='w-full flex flex-col space-y-2'></div>
                </div>
                <h3 className='text-2xl font-semibold text-left'>Infos (English)</h3>
                <div className=' divider'></div>
                <div className=' grid grid-cols-2 gap-5'>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventName" className=' font-semibold text-lg'>Trail Name</label>
                        <input id='eventName' type="text" value={Name} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setName) }} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventDescription" className=' font-semibold text-lg'>Hero Description</label>
                        <textarea id='eventDescription' maxLength={250} value={shortDescription} className='p-3 bg-[#A55B2730] rounded-md text-left px-7 h-12' onChange={(e) => { updateData(e, setShortDescription) }} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventDescription" className=' font-semibold text-lg'>Trail Description</label>
                        <textarea id='eventDescription' value={description} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setDescription) }} />
                    </div>
                </div>
                <h3 className='text-2xl font-semibold text-left'>Infos (Arabic)</h3>
                <div className=' divider'></div>
                <div className=' grid grid-cols-2 gap-5'>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventName" className=' font-semibold text-lg'>Trail Name</label>
                        <input id='eventName' type="text" value={NameAr} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setNameAr) }} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventDescription" className=' font-semibold text-lg'>Hero Description</label>
                        <textarea id='eventDescription' maxLength={250} value={shortDescriptionAr} className='p-3 bg-[#A55B2730] rounded-md text-left px-7 h-12' onChange={(e) => { updateData(e, setShortDescriptionAr) }} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventDescription" className=' font-semibold text-lg'>Trail Description</label>
                        <textarea id='eventDescription' value={descriptionAr} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setDescriptionAr) }} />
                    </div>
                </div>
                <div className=' grid grid-cols-2 gap-5'>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="trailDistance" className=' font-semibold text-lg'>Trail Distance</label>
                        <input id='trailDistance' type="number" value={distance} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setDistance) }} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="trailTime" className=' font-semibold text-lg'>Trail Time</label>
                        <input id='trailTime' type="text" value={timeToFinish} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setTimeToFinish) }} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="trailDifficulty" className=' font-semibold text-lg'>Trail Difficulty</label>
                        <DifficultyRange onDifficultyChange={handleDifficultyChange} />
                        {/* <input id='trailDifficulty' type="range" step={1} maxLength={5} value={difficulty} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setDifficulty) }} /> */}
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventName" className=' font-semibold text-lg'>Trail Location</label>
                        <input id='eventName' type="text" value={trailLocation} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setTrailLocation) }} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventName" className=' font-semibold text-lg'>Trail City</label>
                        <select className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { setCityId(e.target.value) }}>
                            <option value="" selected hidden>Select</option>
                            {
                                citiesData.map((city, index) => (
                                    <option value={city.id}> {city.name} </option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="modal-action">
                    {/* if there is a button, it will close the modal */}
                    {
                        heroPicureLink && bodyPictureLink && pdfFileLink ?
                            <div className="btn bg-[#4f8e5469] text-[#4f8e54]" onClick={() => { createEvent() }}>Create</div>
                            : null
                    }
                    <button className="btn bg-[#8E4F4F69] text-[#8E4F4F]">Close</button>
                </div>
            </form>
        </dialog>
    )
}

export const TimeRangeInput = ({ handleStartTimeChange, handleEndTimeChange }) => {

    return (
        <div className="bg-[#A55B2730] p-3 rounded-md shadow-md flex flex-row justify-evenly">
            <div className=' flex flex-col'>
                <label className="block mb-2 font-semibold">Start Time:</label>
                <input
                    type="time"
                    // value={startTime}
                    onChange={handleStartTimeChange}
                    className="border rounded-md p-2 mb-4"
                />
            </div>
            <div className=' flex flex-col'>
                <label className="block mb-2 font-semibold">End Time:</label>
                <input
                    type="time"
                    // value={endTime}
                    onChange={handleEndTimeChange}
                    className="border rounded-md p-2"
                />
            </div>
        </div>
    );
}

export const DifficultyRange = ({ onDifficultyChange }) => {
    const [selectedDifficulty, setSelectedDifficulty] = useState(2); // Default difficulty level

    const handleDifficultyChange = (level) => {
        setSelectedDifficulty(level);
        // console.log(level)
        onDifficultyChange(level);
    };

    return (
        <div className="flex items-center space-x-4">
            {/* <span>Select Difficulty:</span> */}
            {[1, 2, 3, 4, 5].map((level) => (
                <div
                    key={level}
                    onClick={() => handleDifficultyChange(level)}
                    className={`${selectedDifficulty === level
                        ? 'bg-[#A55B2730] text-black'
                        : 'bg-gray-200 text-gray-600'
                        } px-4 py-2 rounded-lg focus:outline-none w-full text-center`}
                >
                    {level}
                </div>
            ))}
        </div>
    );
};