import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { HiInformationCircle } from 'react-icons/hi2'
import { TiCancel, TiTick } from 'react-icons/ti'
import { useNavigate } from 'react-router'
import Swal from 'sweetalert2'

const FeesList = () => {
    const navigate = useNavigate()

    const detailsModelRef = useRef(null);

    const openDetailsModal = () => {
        if (detailsModelRef.current) {
            detailsModelRef.current.showModal();
        }
    };

    const [feesData, setFeesData] = useState([])
    const [apiBaseUrl, setApiBaseUrl] = useState(axios.defaults.baseURL)


    const getAllFees = () => {
        const token = localStorage.getItem("token")
        axios.get(`fees/infos/?token=${token}`)
            .then(res => {
                setFeesData(res.data)
            })
            .catch(error => {
                console.log(error)
                Swal.fire(
                    "Error!",
                    `${error.response.data}`,
                    "error"
                )
                navigate("/login", { replace: true })
            })
    }

    const deleteFee = (id) => {
        Swal.fire({
            title: "Are You Sure?",
            text: "This fee will be deleted definitely",
            showCancelButton: true,
        })
            .then(res => {
                if (res.isConfirmed) {
                    axios.delete(`fees/${id}`)
                        .then(res => {
                            getAllFees()
                            Swal.fire(
                                "Success!",
                                "Fee Deleted with success",
                                "success"
                            )
                            closeDetailsModal()
                            getAllFees()
                        })
                } else {
                    Swal.fire(
                        "Info!",
                        `Action is cancelled`,
                        "info"
                    )
                }
            })

    }

    useEffect(() => {
        getAllFees();
        // console.log(axios.defaults.baseURL)
    }, [])

    const closeDetailsModal = () => {
        if (detailsModelRef.current) {
            detailsModelRef.current.close();
        }
    };

    return (
        <React.Fragment>
            <div className=' flex flex-col items-center w-screen py-10 space-y-10'>
                <div className=' flex flex-row w-10/12'>
                    <div className=' w-full'>
                        <p className=' p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Fees List</p>
                    </div>
                </div>
                <div className="overflow-x-auto w-10/12">
                    <table className="table table-zebra">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Fees Title</th>
                                <th>Fees Description</th>
                                <th>Fees Amout</th>
                                <th>Creation Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                feesData.map((fee) => (
                                    <tr key={fee.id}>
                                        <th>{fee.id}</th>
                                        <td>{fee.title}</td>
                                        <td>{fee.description}</td>
                                        <td>{fee.amount + " AED"}</td>
                                        <td>{moment(fee.createdAt).format("MMM Do YYYY")}</td>
                                        <td>
                                            <div className=' flex flex-row space-x-3' >
                                                <div className='tooltip hover:cursor-pointer' data-tip="Fee Details" onClick={() => { openDetailsModal() }}>
                                                    <HiInformationCircle size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                                                    <DetailsModel raf={detailsModelRef} data={fee} url={apiBaseUrl.replace('/api/v1', '')} getFees={getAllFees} closeModal={closeDetailsModal} />
                                                </div>
                                                <div className='tooltip hover:cursor-pointer' data-tip="Delete Fee" onClick={() => { deleteFee(fee.id) }}>
                                                    <TiCancel size={24} color='#8E4F4F' className=' bg-[#8E4F4F69] rounded-md' />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FeesList


export const DetailsModel = ({ url, data, raf, getFees, closeModal }) => {

    const [isEdit, setIsEdit] = useState(false)
    const [newTitle, setNewTitle] = useState("")
    const [newDescription, setNewDescription] = useState("")
    const [newAmount, setNewAmount] = useState(0)

    const updateData = (e, state) => {
        state(e.target.value)
    }

    const updateFee = () => {
        const datas = {
            title: newTitle === "" ? data.title : newTitle,
            description: newDescription === "" ? data.description : newDescription,
            amount: newAmount === 0 ? data.amount : newAmount
        }

        axios.put(`fees/${data.id}`, datas)
            .then(res => {
                getFees()
                Swal.fire(
                    "Success!",
                    "Fee Updated with success",
                    "success"
                )
                setNewAmount(0)
                setNewDescription("")
                setNewTitle("")
                setIsEdit(false)
                closeModal()
            })
            .catch(err => {
                Swal.fire(
                    "Error!",
                    `${err.response.data.message}`,
                    "error"
                )
            })
    }

    return (
        <dialog className="modal" ref={raf}>
            <form method="dialog" className="modal-box w-11/12 max-w-5xl items-start">
                <div className=' flex flex-row items-center justify-between'>
                    <h3 className='text-2xl font-semibold text-left'>Fee Informations</h3>
                    {
                        isEdit ?
                            <div className=' text-red-500 text-xl font-semibold' onClick={() => { setIsEdit(!isEdit) }}>
                                Edit
                            </div>
                            :
                            <div className=' text-blue-500 text-xl font-semibold' onClick={() => { setIsEdit(!isEdit) }}>
                                Edit
                            </div>
                    }
                </div>
                <div className=' divider'></div>
                <div className=' grid grid-cols-2 gap-5'>
                    <div className=' flex flex-col space-y-2'>
                        <h3 className=' text-left'>Title</h3>
                        {
                            isEdit ?
                                <input type="text" value={newTitle} onChange={(e) => { updateData(e, setNewTitle) }} placeholder={data.title} className='p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7' />
                                :
                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                    {data.title}
                                </div>
                        }
                    </div>
                    <div className=' flex flex-col space-y-2'>
                        <h3 className=' text-left'>Description</h3>
                        {
                            isEdit ?
                                <input type="text" value={newDescription} onChange={(e) => { updateData(e, setNewDescription) }} placeholder={data.description} className='p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7' />
                                :
                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                    {data.description}
                                </div>
                        }
                    </div>
                    <div className=' flex flex-col space-y-2'>
                        <h3 className=' text-left'>Amount</h3>
                        {
                            isEdit ?
                                <input type="number" value={newAmount} onChange={(e) => { updateData(e, setNewAmount) }} placeholder={data.amount} className='p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7' />
                                :
                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                    {data.amount + " AED"}
                                </div>
                        }
                    </div>
                </div>
                <div className="modal-action">
                    {/* if there is a button, it will close the modal */}
                    <button className="btn bg-[#8E4F4F69] text-[#8E4F4F]">Close</button>
                    {
                        isEdit
                            ?
                            <div className='btn bg-[#4f8e5469] text-[#4f8e54]' onClick={() => { updateFee() }}>
                                Update Fee
                            </div>
                            :
                            null
                    }
                </div>
            </form>
        </dialog>
    )
}