import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'

const PaymentDetails = ({ showPopUp, closeModal, data }) => {
    let paymentData;

    const [showModal, setShowModal] = useState(showPopUp);

    if (data) {
        paymentData = JSON.parse(data.paymentInfo)
    } else {
        console.log("empty array")
    }

    useEffect(() => {
        setShowModal(showPopUp);
    }, [showPopUp]);

    return (
        <Fragment>
            <Transition appear show={showModal} as={Fragment}>
                <Dialog as='div' className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900 pb-4"
                                    >
                                        Payment Details
                                    </Dialog.Title>
                                    {
                                        paymentData != null &&
                                        <div className='flex flex-col w-full space-y-10'>
                                            <div className='grid grid-cols-3 gap-5'>
                                                <div className=' flex flex-col space-y-2'>
                                                    <h3 className=' text-left'>Payment Action</h3>
                                                    <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                        {paymentData.action}
                                                    </div>
                                                </div>
                                                <div className=' flex flex-col space-y-2'>
                                                    <h3 className=' text-left'>Payment Type</h3>
                                                    <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                        {paymentData.type}
                                                    </div>
                                                </div>
                                                <div className=' flex flex-col space-y-2'>
                                                    <h3 className=' text-left'>Payment Amount</h3>
                                                    <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                        {paymentData.formattedAmount}
                                                    </div>
                                                </div>
                                                <div className=' flex flex-col space-y-2'>
                                                    <h3 className=' text-left'>Payment Reference</h3>
                                                    <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                        {paymentData.reference}
                                                    </div>
                                                </div>
                                                <div className=' flex flex-col space-y-2'>
                                                    <h3 className=' text-left'>Outlet ID</h3>
                                                    <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                        {paymentData.outletId}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </Fragment>
    )
}

export default PaymentDetails