import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { HiInformationCircle } from 'react-icons/hi2'
import { TiCancel, TiTick } from 'react-icons/ti'
import { useNavigate } from 'react-router'
import Swal from 'sweetalert2'

const EventsList = () => {
    const navigate = useNavigate()

    const detailsModelRef = useRef(null);

    const openDetailsModal = () => {
        if (detailsModelRef.current) {
            detailsModelRef.current.showModal();
        }
    };

    const [eventsData, setEventsData] = useState([])
    const [eventData, setEventData] = useState(null)
    const [apiBaseUrl, setApiBaseUrl] = useState(axios.defaults.baseURL)


    const getAllEvents = () => {
        const token = localStorage.getItem("token")
        axios.get(`permit/events/?token=${token}`)
            .then(res => {
                setEventsData(res.data)
            })
            .catch(error => {
                console.log(error)
                Swal.fire(
                    "Error!",
                    `${error.response.data}`,
                    "error"
                )
                navigate("/login", { replace: true })
            })
    }

    useEffect(() => {
        getAllEvents();
    }, [])

    return (
        <React.Fragment>
            <div className=' flex flex-col items-center w-screen py-10 space-y-10'>
                <div className=' flex flex-row w-10/12'>
                    <div className=' w-full'>
                        <p className=' p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Daily registred Events</p>
                    </div>
                </div>
                <div className="overflow-x-auto w-10/12">
                    <table className="table table-zebra">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Event Name</th>
                                <th>Event Location</th>
                                <th>Submition Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                eventsData.filter((ev) => ev.name != null).map((event) => (
                                    <tr key={event.id}>
                                        <th>{event.id}</th>
                                        <td>{event.name}</td>
                                        <td>{event.location}</td>
                                        <td>{moment(event.createdAt).format("MMM Do YYYY")}</td>
                                        <td>
                                            <div className=' flex flex-row space-x-3' >
                                                <div className='tooltip hover:cursor-pointer' data-tip="Event Details" onClick={() => { setEventData(event); openDetailsModal() }}>
                                                    <HiInformationCircle size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                                                    
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className=' flex flex-row w-10/12'>
                    <div className=' w-full'>
                        <p className=' p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Yearly registred Events</p>
                    </div>
                </div>
                <div className="overflow-x-auto w-10/12">
                    <table className="table table-zebra">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Event Name</th>
                                {/* <th>Event Location</th> */}
                                {/* <th>Submition Date</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                eventsData.filter((ev) => ev.name === null).map((event) => (
                                    <tr key={event.id}>
                                        <th>{event.id}</th>
                                        <td>{moment(event.createdAt).format("MMM Do YYYY")}</td>
                                        <td>
                                            <div className='flex flex-row space-x-3'>
                                                <div
                                                    className='tooltip hover:cursor-pointer'
                                                    data-tip="Event Details"
                                                    onClick={() => {
                                                        setEventData(event); // Update state with the correct event
                                                        openDetailsModal(); // Open modal after state is set
                                                    }}
                                                >
                                                    <HiInformationCircle
                                                        size={24}
                                                        color='#3f85ee'
                                                        className='bg-[#3f85ee69] rounded-md'
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>

                        <DetailsModel raf={detailsModelRef} data={eventData} url={apiBaseUrl.replace('/api/v1', '')} />
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EventsList


export const DetailsModel = ({ url, data, raf }) => {

    let activityString;

    if (data) {
        // const activityArray = JSON.parse(data?.activityType);
        // const activityTitles = (activityArray).map(activity => activity.title);
        // activityString = activityTitles.join(', ');
    } else {
        console.error('Data or event is null or undefined');
    }

    return (
        <dialog className="modal" ref={raf}>
            {
                data &&
                <form method="dialog" className="modal-box w-11/12 max-w-5xl items-start">
                    <h3 className='text-2xl font-semibold text-left mt-6'>Event</h3>
                    <div className=' divider'></div>
                    <div className=' grid grid-cols-3 gap-5'>
                        {data.name &&
                            <div className=' flex flex-col space-y-2'>
                                <h3 className=' text-left'>Name</h3>
                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                    {data.name}
                                </div>
                            </div>
                        }
                        {data.location &&
                            <div className=' flex flex-col space-y-2'>
                                <h3 className=' text-left'>Location</h3>
                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                    {data.location}
                                </div>
                            </div>
                        }
                        {data.name ?
                            <div className=' flex flex-col space-y-2'>
                                <h3 className=' text-left'>Permit Type</h3>
                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                    {"One Day"}
                                </div>
                            </div>
                            :
                            <div className=' flex flex-col space-y-2'>
                                <h3 className=' text-left'>Permit Type</h3>
                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                    {"One Year"}
                                </div>
                            </div>
                        }
                        {
                            data.companyInsurancePolicy &&
                            <div className=' flex flex-col space-y-2'>
                                <h3 className=' text-left'>Insurance Policy</h3>
                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                    <a href={`${url}${data.companyInsurancePolicy}`} target='_blank'>
                                        View File
                                    </a>
                                </div>
                            </div>
                        }
                        {data.acknowledge &&
                            <div className=' flex flex-col space-y-2'>
                                <h3 className=' text-left'>Event Itinerary</h3>
                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                    <a href={`${url}${data.acknowledge}`} target='_blank'>
                                        View File
                                    </a>
                                </div>
                            </div>
                        }
                        {
                            data.implementationPlan &&
                            <div className=' flex flex-col space-y-2'>
                                <h3 className=' text-left'>Implementation Plan</h3>
                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                    <a href={`${url}${data.implementationPlan}`} target='_blank'>
                                        View File
                                    </a>
                                </div>
                            </div>
                        }
                        {
                            data.internationalTradeMarkLicense &&
                            <div className=' flex flex-col space-y-2'>
                                <h3 className=' text-left'>International Brand License</h3>
                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                    <a href={`${url}${data.internationalTradeMarkLicense}`} target='_blank'>
                                        View File
                                    </a>
                                </div>
                            </div>
                        }
                        {
                            data.riskManagmentDraft &&
                            <div className=' flex flex-col space-y-2'>
                                <h3 className=' text-left'>Risk Managment Draft</h3>
                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                    <a href={`${url}${data.riskManagmentDraft}`} target='_blank'>
                                        View File
                                    </a>
                                </div>
                            </div>
                        }
                        {
                            data?.activityType &&
                            <div className=' flex flex-col space-y-2'>
                                <h3 className=' text-left'>Activity Type</h3>
                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                    {data?.activityType}
                                </div>
                            </div>
                        }
                    </div>
                    <div className="modal-action">
                        {/* if there is a button, it will close the modal */}
                        <button className="btn bg-[#8E4F4F69] text-[#8E4F4F]">Close</button>
                    </div>
                </form>
            }
        </dialog>
    )
}