import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import Swal from 'sweetalert2'

const AddFee = () => {

  const history = useNavigate()

  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [descriptionAr, setDescriptionAr] = useState("")
  const [amount, setAmount] = useState(0)

  const updateData = (e, state) => {
    state(e.target.value)
  }

  const addFee = () => {
    const data = {
      title: title,
      description: description,
      titleAr: titleAr,
      descriptionAr: descriptionAr,
      amount: amount
    }

    const token = localStorage.getItem("token")

    axios.post(`fees/create/?token=${token}`, data)
      .then(res => {
        Swal.fire({
          title: "Success!",
          text: "Fee Added successfully",
          icon: "success"
        })
        setAmount(0)
        setDescription("")
        setTitle("")
        setTimeout(() => {
          history("/fees_list", { replace: true })
        }, 1200);
      })
      .catch(err => {
        Swal.fire(
          "Error!",
          `${err.response.data.message}`
        )
      })
  }

  return (
    <React.Fragment>
      <div className=' flex flex-col items-center w-screen py-10 space-y-10'>
        <div className=' flex flex-row w-10/12'>
          <div className=' w-full'>
            <p className=' p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>New Fee option</p>
          </div>
        </div>
        <div className='w-10/12 flex flex-col'>
          <h3 className='text-2xl font-semibold text-left'>Infos (English)</h3>
          <div className=' divider'></div>
          <div className=' grid grid-cols-2 gap-5'>
            <div className='w-full flex flex-col space-y-2'>
              <label htmlFor="feeTitle" className=' font-semibold text-lg'>Fee Title</label>
              <input id='feeTitle' type="text" value={title} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setTitle) }} />
            </div>
            <div className='w-full flex flex-col space-y-2'>
              <label htmlFor="feeDescription" className=' font-semibold text-lg'>Fee Description</label>
              <textarea id='feeDescription' type="text" value={description} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setDescription) }} />
            </div>
          </div>
          <h3 className='text-2xl font-semibold text-left'>Infos (Arabic)</h3>
          <div className=' divider'></div>
          <div className=' grid grid-cols-2 gap-5'>
            <div className='w-full flex flex-col space-y-2'>
              <label htmlFor="feeTitle" className=' font-semibold text-lg'>Fee Title</label>
              <input id='feeTitle' type="text" value={titleAr} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setTitleAr) }} />
            </div>
            <div className='w-full flex flex-col space-y-2'>
              <label htmlFor="feeDescription" className=' font-semibold text-lg'>Fee Description</label>
              <textarea id='feeDescription' type="text" value={descriptionAr} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setDescriptionAr) }} />
            </div>
          </div>
          <div className=' grid grid-cols-2 gap-5'>
            <div className='w-full flex flex-col space-y-2'>
              <label htmlFor="feeAmount" className=' font-semibold text-lg'>Fee Amount</label>
              <input id='feeAmount' type="number" value={amount} className='p-3 bg-[#A55B2730] rounded-md  text-left px-7' onChange={(e) => { updateData(e, setAmount) }} />
            </div>
          </div>
        </div>
        <div className=' flex flex-row items-center justify-center'>
          <button className=' p-3 bg-[#A55B27] text-white rounded-lg px-20' onClick={() => { addFee() }}>Add Fee</button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AddFee