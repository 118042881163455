import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { HiInformationCircle } from 'react-icons/hi2'
import { TiCancel, TiTick } from 'react-icons/ti'
import Swal from 'sweetalert2'
import { BiShowAlt, BiHide } from "react-icons/bi";
import { RiEdit2Line } from "react-icons/ri";


const FujairahEvents = () => {

  const detailsModelRef = useRef(null);
  const createEventRef = useRef(null)
  const updateEventRef = useRef(null);

  const openDetailsModal = (event) => {
    setSelectedEvent(event);
    if (detailsModelRef.current) {
      detailsModelRef.current.showModal();
    }
  };
  const openUpdateModal = (event) => {
    setSelectedEvent(event);
    if (updateEventRef.current) {
      updateEventRef.current.showModal();
    }
  };

  const openCreateEventModal = () => {
    if (createEventRef.current) {
      createEventRef.current.showModal();
    }
  };

  const [eventsData, setEventsData] = useState([])
  const [apiBaseUrl, setApiBaseUrl] = useState(axios.defaults.baseURL)
  const [selectedEvent, setSelectedEvent] = useState([]);


  const getAllEvents = () => {
    const token = localStorage.getItem("token")
    axios.get(`events/admin/infos/?token=${token}`)
      .then(res => {
        setEventsData(res.data)
        console.log(res.data)
      })
      .catch(error => {
        console.log(error)
        Swal.fire(
          "Error!",
          `${error.response.data.message}`,
          "error"
        )
      })
  }

  const closeCreateEventModal = () => {
    if (createEventRef.current) {
      createEventRef.current.close();
    }
  };
  const closeUpdateEventModal = () => {
    if (updateEventRef.current) {
      updateEventRef.current.close();
    }
  };

  useEffect(() => {
    getAllEvents();
    // console.log(axios.defaults.baseURL)
  }, [])

  const deleteEventPopUp = (eventId) => {
    Swal.fire({
      title: "Delete Event",
      html: "Are you sure you want to delete this event?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Approve"
    })
      .then((res) => {
        if (res.isConfirmed) {
          deleteEvent(eventId)
        } else {
          Swal.fire(
            "Info!",
            "Action cancelled",
            "info"
          )
        }
      })
  }
  const deleteEvent = (eventId) => {
    const token = localStorage.getItem("token")
    axios.delete(`events/delete/${eventId}/?token=${token}`)
      .then(res => {
        console.log(res.data);
        getAllEvents()
      })
      .catch(err => {
        Swal.fire(
          "Error!",
          `${err.response.data.message}`,
          "error"
        )
      })
  }

  const updateStatus = (eventId, status) => {
    const token = localStorage.getItem("token")
    const url = status !== true ? `events/update/show/${eventId}/?token=${token}` : `events/update/hide/${eventId}/?token=${token}`;
    console.log(url)
    axios.put(url)
      .then(res => {
        console.log(res.data);
        Swal.fire(
          "Success!",
          `${res.data}`,
          "success"
        )
        getAllEvents()
      })
      .catch(err => {
        Swal.fire(
          "Error!",
          `${err.response.data.message}`,
          "error"
        )
      })
  }

  return (
    <div className=' flex flex-col items-center w-screen py-10 space-y-10'>
      <div className=' flex flex-row w-10/12 items-center p-4 bg-[#DCC3B1] rounded-xl '>
        <div className=' w-full'>
          <p className=' text-xl font-semibold capitalize'>Fujairah Events List</p>
        </div>
        <div>
          <button className='btn bg-white' onClick={() => { openCreateEventModal() }} >Create Event</button>
          <CreateEventModal raf={createEventRef} getData={getAllEvents} closeModal={closeCreateEventModal} />
        </div>
      </div>
      <div className="overflow-x-auto w-10/12">
        <table className="table table-zebra">
          <thead>
            <tr>
              <th></th>
              <th>Event Name</th>
              <th>Event Location</th>
              <th>Event Date</th>
              <th>Event Time</th>
              <th>Event Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              eventsData.map((event, index) => (
                <tr key={index}>
                  <th>{event.id}</th>
                  <td>{event.name}</td>
                  <td>{event.location}</td>
                  <td>{moment(event.date).format("MMMM Do YYYY")}</td>
                  <td>{event.startTime + "-" + event.endTime}</td>
                  <td>{(event.description).slice(0, 250) + "..."}</td>
                  <td>
                    <div className=' flex flex-row space-x-3' >
                      <div className='tooltip hover:cursor-pointer' data-tip={event.showOnHomeScreen !== true ? "Show on home" : "Hide on home"} onClick={() => { updateStatus(event.id, event.showOnHomeScreen) }}>
                        {
                          event.showOnHomeScreen !== true ?
                            <BiShowAlt size={24} color='#0d9e00' className=' bg-[#0d9e0069] rounded-md' />
                            :
                            <BiHide size={24} color='#ea3737' className=' bg-[#ea373769] rounded-md' />
                        }

                      </div>
                      <div className='tooltip hover:cursor-pointer' data-tip="Update Event" onClick={() => { openUpdateModal(event); console.log(event) }}>
                        <RiEdit2Line size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                        <UpdateEventModal raf={updateEventRef} data={selectedEvent} url={apiBaseUrl.replace('/api/v1', '')} getData={getAllEvents} closeModal={closeUpdateEventModal} />
                      </div>
                      <div className='tooltip hover:cursor-pointer' data-tip="Event Details" onClick={() => { openDetailsModal(event); console.log(event) }}>
                        <HiInformationCircle size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                        <DetailsModel raf={detailsModelRef} data={selectedEvent} url={apiBaseUrl.replace('/api/v1', '')} />
                      </div>
                      <div className='tooltip hover:cursor-pointer' data-tip="Delete Event" onClick={() => { deleteEventPopUp(event.id) }}>
                        <TiCancel size={24} color='#8E4F4F' className=' bg-[#8E4F4F69] rounded-md' />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default FujairahEvents


export const DetailsModel = ({ url, data, raf, users }) => {
  return (
    <dialog className="modal" ref={raf}>
      <form method="dialog" className="modal-box w-11/12 max-w-5xl items-start space-y-4">
        <div className=' grid grid-cols-2 gap-5'>
          <div className='w-full flex flex-col space-y-2'>
            {
              data.bodyPicture !== null ?
                <img src={url + data.bodyPicture} className=' object-cover w-full h-full' alt="" />
                :
                null
            }
            <label htmlFor="bodyPicture" className=' font-semibold text-lg'>Body Picture</label>
          </div>
          <div className='w-full flex flex-col space-y-2'>
            {
              data.heroPicture !== null ?
                <img src={url + data.heroPicture} className=' object-cover w-full h-full' alt="" />
                :
                null
            }
            <label htmlFor="heroPicture" className=' font-semibold text-lg'>Hero Picture</label>
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventName" className=' font-semibold text-lg'>Event Name</label>
            <div id='eventName' type="text" className='p-3 bg-[#A55B2730] rounded-md text-left px-7'>
              {data.name}
            </div>
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventName" className=' font-semibold text-lg'>Event Name (Arabic)</label>
            <div id='eventName' type="text" className='p-3 bg-[#A55B2730] rounded-md text-left px-7'>
              {data.nameAr}
            </div>
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventLocation" className=' font-semibold text-lg'>Event Location</label>
            <div id='eventLocation' type="text" className='p-3 bg-[#A55B2730] rounded-md  text-left px-7'>
              {data.location}
            </div>
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventLocation" className=' font-semibold text-lg'>Event Location(Arabic)</label>
            <div id='eventLocation' type="text" className='p-3 bg-[#A55B2730] rounded-md  text-left px-7'>
              {data.locationAr}
            </div>
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventDescription" className=' font-semibold text-lg'>Event Description</label>
            <div id='eventDescription' className='p-3 bg-[#A55B2730] rounded-md text-left px-7'>
              {data.description}
            </div>
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventDescription" className=' font-semibold text-lg'>Event Description(Arabic)</label>
            <div id='eventDescription' className='p-3 bg-[#A55B2730] rounded-md text-left px-7'>
              {data.descriptionAr}
            </div>
          </div>
          {
            data.externalLink &&
            <div className='w-full flex flex-col space-y-2'>
              <label htmlFor="eventDescription" className=' font-semibold text-lg'>External Link (Subscription)</label>
              <div id='eventDescription' className='p-3 bg-[#A55B2730] rounded-md text-left px-7'>
                {data.externalLink}
              </div>
            </div>
          }
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventDate" className=' font-semibold text-lg'>Event Date & Time</label>
            <div id='eventDate' type="datetime-local" className='p-3 bg-[#A55B2730] rounded-md  text-left px-7'>
              {moment(data.date).format("MMMM Do YYYY") + " / " + (data.startTime + "-" + data.endTime)}
            </div>
          </div>
        </div>
        <div className=' flex flex-row w-full items-center p-4 bg-[#DCC3B1] rounded-xl '>
          <div className=' w-full'>
            <p className=' text-xl font-semibold capitalize'>List of Participants</p>
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra">
            <thead>
              <tr>
                <th></th>
                <th>Full Name</th>
                <th>E-mail address</th>
                <th>Emirate ID</th>
              </tr>
            </thead>
            <tbody>
              {
                data.users ?
                  (data.users).map((User) => (
                    <tr key={User.id}>
                      <th>{User.id || User.users_id}</th>
                      <td>{User.fullName || User.users_fullName}</td>
                      <td>{User.email || User.users_email}</td>
                      {
                        User.users_emiratesID &&
                        <td className=' text-black'><a href={url + User.users_emiratesID} target='_blank'>Link</a></td>
                      }
                    </tr>
                  ))
                  : null
              }
            </tbody>
          </table>
        </div>
        <div className="modal-action">
          {/* if there is a button, it will close the modal */}
          <button className="btn bg-[#8E4F4F69] text-[#8E4F4F]">Close</button>
        </div>
      </form>
    </dialog>
  )
}

export const CreateEventModal = ({ raf, getData, closeModal }) => {

  const [Name, setName] = useState('')
  const [location, setLocation] = useState('')
  const [description, setDescription] = useState('')
  const [NameAr, setNameAr] = useState('')
  const [locationAr, setLocationAr] = useState('')
  const [descriptionAr, setDescriptionAr] = useState('')
  const [date, setDate] = useState(null)
  const [bodyPictureLink, setBodyPictureLink] = useState("")
  const [heroPicureLink, setHeroPicureLink] = useState("")
  const [bodyPicture, setBodyPicture] = useState(null)
  const [heroPicture, setHeroPicture] = useState(null)
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [detailsText, setDetailsText] = useState("");
  const [detailsTextAr, setDetailsTextAr] = useState("");
  const [longitude, setLongitude] = useState("")
  const [latitude, setLatitude] = useState("")
  const [externalLink, setExternalLink] = useState(null)

  const updateData = (e, state) => {
    state(e.target.value)
  }

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };

  const uploadVideo = async (file, setLinkState) => {
    const formData = new FormData();
    formData.append('userFile', file); // Use the same field name as in your Express route

    try {
      const response = await axios.post('upload/upload_file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        },
      });
      setLinkState(response.data)

      console.log('File uploaded successfully:', response.data);
    } catch (error) {
      Swal.fire(
        "Error!",
        `${error}`,
        "error"
      )
    }
  }

  const createEvent = () => {
    const datas = {
      bodyPicture: bodyPictureLink,
      heroPicture: heroPicureLink,
      name: Name,
      location: location,
      description: description,
      nameAr: NameAr,
      locationAr: locationAr,
      descriptionAr: descriptionAr,
      date: date,
      startTime: startTime,
      endTime: endTime,
      detailsText: detailsText,
      detailsTextAr: detailsTextAr,
      longitude: longitude,
      latitude: latitude,
      externalLink: externalLink
    }
    const token = localStorage.getItem("token")

    axios.post(`events/create/?token=${token}`, datas)
      .then(res => {
        console.log(res.data)
        closeModal()
        Swal.fire(
          "Success!",
          "Event successfully created",
          "success"
        )
        setBodyPictureLink("")
        setBodyPicture(null)
        setHeroPicureLink("")
        setHeroPicture(null)
        setName("")
        setLocation("")
        setDescription("")
        setNameAr("")
        setLocationAr("")
        setDescriptionAr("")
        setDetailsText("")
        setDetailsTextAr("")
        setExternalLink(null)
        setLatitude("")
        setLatitude("")
        setDate(null)
        getData()

      })
      .catch(err => {
        Swal.fire(
          "Error!",
          `${err.response.data.message}`,
          "error"
        )
      })
  }

  return (
    <dialog className="modal" ref={raf}>
      <form method="dialog" className="modal-box w-11/12 max-w-5xl items-start space-y-2">
        <div className=' grid grid-cols-2 gap-5'>
          <div className='w-full flex flex-col space-y-2'>
            {
              bodyPicture !== null ?
                <img src={URL.createObjectURL(bodyPicture)} className=' object-cover w-full h-full' alt="" />
                :
                null
            }
            <label htmlFor="bodyPicture" className=' font-semibold text-lg'>Body Picture</label>
            <input id='bodyPicture' onChange={(e) => { setBodyPicture(e.target.files[0]); uploadVideo(e.target.files[0], setBodyPictureLink) }} type="file" className="file-input file-input-bordered w-full max-w-xs" />
          </div>
          <div className='w-full flex flex-col space-y-2'>
            {
              heroPicture !== null ?
                <img src={URL.createObjectURL(heroPicture)} className=' object-cover w-full h-full' alt="" />
                :
                null
            }
            <label htmlFor="heroPicture" className=' font-semibold text-lg'>Hero Picture</label>
            <input id='heroPicture' onChange={(e) => { setHeroPicture(e.target.files[0]); uploadVideo(e.target.files[0], setHeroPicureLink) }} type="file" className="file-input file-input-bordered w-full max-w-xs" />
          </div>
        </div>
        <h3 className='text-2xl font-semibold text-left'>Infos (English)</h3>
        <div className=' divider'></div>
        <div className=' grid grid-cols-2 gap-5'>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventName" className=' font-semibold text-lg'>Event Name (English)</label>
            <input id='eventName' type="text" value={Name} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setName) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventLocation" className=' font-semibold text-lg'>Event Location (English)</label>
            <input id='eventLocation' type="text" value={location} className='p-3 bg-[#A55B2730] rounded-md  text-left px-7' onChange={(e) => { updateData(e, setLocation) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventDescription" className=' font-semibold text-lg'>Event Description (English)</label>
            <textarea id='eventDescription' value={description} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setDescription) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventDescription" className=' font-semibold text-lg'>Event Details (English)</label>
            <textarea id='eventDescription' value={detailsText} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setDetailsText) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
          </div>
        </div>
        <h3 className='text-2xl font-semibold text-left'>Infos (Arabic)</h3>
        <div className=' divider'></div>
        <div className=' grid grid-cols-2 gap-5'>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventName" className=' font-semibold text-lg'>Event Name (Arabic)</label>
            <input id='eventName' type="text" value={NameAr} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setNameAr) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventLocation" className=' font-semibold text-lg'>Event Location (Arabic)</label>
            <input id='eventLocation' type="text" value={locationAr} className='p-3 bg-[#A55B2730] rounded-md  text-left px-7' onChange={(e) => { updateData(e, setLocationAr) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventDescription" className=' font-semibold text-lg'>Event Description (Arabic)</label>
            <textarea id='eventDescription' value={descriptionAr} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setDescriptionAr) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventDescription" className=' font-semibold text-lg'>Event Details (Arabic)</label>
            <textarea id='eventDescription' value={detailsTextAr} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setDetailsTextAr) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
          </div>
        </div>
        <div className=' grid grid-cols-2 gap-5'>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventDescription" className=' font-semibold text-lg'>Longitude</label>
            <input id='eventDescription' value={longitude} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setLongitude) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventDescription" className=' font-semibold text-lg'>Latitude</label>
            <input id='eventDescription' value={latitude} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setLatitude) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventDescription" className=' font-semibold text-lg'>External Link (Subscription)</label>
            <input id='eventDescription' value={externalLink} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setExternalLink) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventDate" className=' font-semibold text-lg'>Event Date</label>
            <input id='eventDate' type="date" value={date} className='p-3 bg-[#A55B2730] rounded-md  text-left px-7' onChange={(e) => { updateData(e, setDate) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventDate" className=' font-semibold text-lg'>Event Time</label>
            <TimeRangeInput handleEndTimeChange={handleEndTimeChange} handleStartTimeChange={handleStartTimeChange} />
          </div>
        </div>
        <div className="modal-action">
          {/* if there is a button, it will close the modal */}
          {
            heroPicureLink && bodyPictureLink ?
              <div className="btn bg-[#4f8e5469] text-[#4f8e54]" onClick={() => { createEvent() }}>Create</div>
              : null
          }
          <button className="btn bg-[#8E4F4F69] text-[#8E4F4F]">Close</button>
        </div>
      </form>
    </dialog>
  )
}
export const UpdateEventModal = ({ raf, getData, closeModal, data }) => {

  const [formData, setFormData] = useState({
    title: data.name,
    titleAr: data.nameAr,
    description: data.description,
    descriptionAr: data.descriptionAr,
    date: data.date,
    startTime: data.startTime,
    endTime: data.endTime
  })

  useEffect(() => {
    if (data) {
      setFormData({
        title: data.name || '',
        titleAr: data.nameAr || '',
        description: data.description || '',
        descriptionAr: data.descriptionAr || '',
        date: data.date || '',
        startTime: data.startTime || '',
        endTime: data.endTime || ''
      });
    }
  }, [data]);

  console.log(formData)

  const handleStartTimeChange = (event) => {
    setFormData({ ...formData, startTime: event.target.value });
  };

  const handleEndTimeChange = (event) => {
    setFormData({ ...formData, endTime: event.target.value });
  };

  const updateEvent = (eventId) => {
    const token = localStorage.getItem("token")

    axios.put(`events/update/event/${eventId}/?token=${token}`, formData)
      .then(res => {
        console.log(res.data)
        closeModal()
        Swal.fire(
          "Success!",
          "Event successfully updated",
          "success"
        )
        getData()

      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <dialog className="modal" ref={raf}>
      <form method="dialog" className="modal-box w-11/12 max-w-5xl items-start space-y-2">
        <h3 className='text-2xl font-semibold text-left'>Infos (English)</h3>
        <div className=' divider'></div>
        <div className=' grid grid-cols-2 gap-5'>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventName" className=' font-semibold text-lg'>Event Name (English)</label>
            <input id='eventName' type="text" value={formData.title} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { setFormData({ ...formData, title: e.target.value }) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventDescription" className=' font-semibold text-lg'>Event Description (English)</label>
            <textarea id='eventDescription' value={formData.description} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { setFormData({ ...formData, description: e.target.value }) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
          </div>
        </div>
        <h3 className='text-2xl font-semibold text-left'>Infos (Arabic)</h3>
        <div className=' divider'></div>
        <div className=' grid grid-cols-2 gap-5'>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventName" className=' font-semibold text-lg'>Event Name (Arabic)</label>
            <input id='eventName' type="text" value={formData.titleAr} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { setFormData({ ...formData, titleAr: e.target.value }) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventDescription" className=' font-semibold text-lg'>Event Description (Arabic)</label>
            <textarea id='eventDescription' value={formData.descriptionAr} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { setFormData({ ...formData, descriptionAr: e.target.value }) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
          </div>
        </div>
        <div className=' grid grid-cols-2 gap-5'>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventDate" className=' font-semibold text-lg'>Event Date</label>
            <input id='eventDate' type="date" value={formData.date} className='p-3 bg-[#A55B2730] rounded-md  text-left px-7' onChange={(e) => { setFormData({ ...formData, date: e.target.value }) }} />
          </div>
          <div className='w-full flex flex-col space-y-2'>
            <label htmlFor="eventDate" className=' font-semibold text-lg'>Event Time</label>
            <TimeRangeInput handleEndTimeChange={handleEndTimeChange} handleStartTimeChange={handleStartTimeChange} />
          </div>
        </div>
        <div className="modal-action">
          {/* if there is a button, it will close the modal */}
          {
            <div className="btn bg-[#4f8e5469] text-[#4f8e54]" onClick={() => { updateEvent(data.id) }}>Update</div>
          }
          <button className="btn bg-[#8E4F4F69] text-[#8E4F4F]">Close</button>
        </div>
      </form>
    </dialog>
  )
}

export const TimeRangeInput = ({ handleStartTimeChange, handleEndTimeChange }) => {

  return (
    <div className="bg-[#A55B2730] p-3 rounded-md shadow-md flex flex-row justify-evenly">
      <div className=' flex flex-col'>
        <label className="block mb-2 font-semibold">Start Time:</label>
        <input
          type="time"
          // value={startTime}
          onChange={handleStartTimeChange}
          className="border rounded-md p-2 mb-4"
        />
      </div>
      <div className=' flex flex-col'>
        <label className="block mb-2 font-semibold">End Time:</label>
        <input
          type="time"
          // value={endTime}
          onChange={handleEndTimeChange}
          className="border rounded-md p-2"
        />
      </div>
    </div>
  );
}