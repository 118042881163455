import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment';
import { Dialog, Transition } from '@headlessui/react';

const UserDetails = ({ url, data, closeModal, showPopUp, type }) => {

    const [showModal, setShowModal] = useState(showPopUp);
    let userData;

    useEffect(() => {
        setShowModal(showPopUp);
    }, [showPopUp]);

    if (data) {
        userData = JSON.parse(data)
        console.log(JSON.parse(data))
    } else {
        console.log("no data")
    }


    return (
        <Fragment>
            <Transition appear show={showModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-6xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900 pb-4"
                                    >
                                        English
                                    </Dialog.Title>
                                    {
                                        data &&
                                        <div className=' flex flex-col w-full space-y-10'>
                                            <div className=' grid grid-cols-3 gap-5'>
                                                {
                                                    userData.fullnameEN &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Full Name English</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {userData.fullnameEN}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    userData.firstnameEN &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>First Name English</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {userData.firstnameEN}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    userData.lastnameEN &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Last Name English</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {userData.lastnameEN}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    userData.nationalityEN &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Nationality English</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {userData.nationalityEN}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    userData.email &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Email</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {userData.email}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    userData.gender &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Gender</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {userData.gender}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    userData.mobile &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Phone Number</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {userData.mobile}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    userData.userType &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>User Type</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {userData.userType}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    userData.idn &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>{"IDN"}</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {userData.idn}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    userData.uuid &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>UUID</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {(userData.uuid).slice(0, -15) + "..."}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    userData.fullnameAR &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Full Name English</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {userData.fullnameAR}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    userData.firstnameAR &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>First Name English</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {userData.firstnameAR}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    userData.lastnameAR &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Last Name English</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {userData.lastnameAR}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    userData.nationalityAR &&
                                                    <div className=' flex flex-col space-y-2'>
                                                        <h3 className=' text-left'>Nationality English</h3>
                                                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                            {userData.nationalityAR}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="modal-action">
                                                {/* if there is a button, it will close the modal */}
                                                {/* <div className="btn bg-[#4f8e5469] text-[#4f8e54]" onClick={() => { approvePermit(data?.id) }}>Approve</div> */}
                                                <button onClick={() => { closeModal() }} className="btn bg-[#8E4F4F69] text-[#8E4F4F]">Close</button>
                                            </div>
                                        </div>
                                    }
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </Fragment>
    )
}

export default UserDetails