import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { HiCurrencyDollar, HiInformationCircle } from 'react-icons/hi2'
import { TiCancel, TiTick } from 'react-icons/ti'
import { useNavigate } from 'react-router'
import Swal from 'sweetalert2'
import DenyPopUp from '../Dashboard/DenyPopUp'
import DetailsPopUp from '../Dashboard/DetailsPopUp'
import PaymentDetails from './PaymentDetails'

const YearlyPermitsList = () => {
    const navigate = useNavigate()

    const detailsModelRef = useRef(null);
    const position = localStorage.getItem("position");

    const openDetailsModal = () => {
        if (detailsModelRef.current) {
            detailsModelRef.current.showModal();
        }
    };

    const [permitsData, setPermitsData] = useState([])
    const [apiBaseUrl, setApiBaseUrl] = useState(axios.defaults.baseURL)
    const [showDenyPopup, setShowDenyPopup] = useState(false)
    const [showInfoPopup, setShowInfoPopup] = useState(false)
    const [showPaymentInfo, setShowPaymentInfo] = useState(false)
    const [permitId, setPermitId] = useState(null)
    const [companyData, setCompanyData] = useState(null)
    const [permitType, setPermitType] = useState("")


    const getAllAwaitingPermits = () => {
        const token = localStorage.getItem("token")
        axios.get(`permit/year/infos/?token=${token}`)
            .then(res => {
                setPermitsData(res.data)
                console.log(res.data)
            })
            .catch(error => {
                console.log(error)
                Swal.fire(
                    "Error!",
                    `${error.response.data}`,
                    "error"
                )
                navigate("/login", { replace: true })
            })
    }

    useEffect(() => {
        getAllAwaitingPermits();
    }, [])

    useEffect(() => {
        const timout = setTimeout(() => {
            getAllAwaitingPermits();
        }, 30000);
        return () => {
            clearTimeout(timout);
        }
    }, [getAllAwaitingPermits])

    const sendApproval = (permitId) => {
        const token = localStorage.getItem("token")
        axios.put(`permit/year/approval/${permitId}/?token=${token}`)
            .then(res => {
                Swal.fire(
                    "Success!",
                    "Approved with success and email will be sent to the company",
                    "success"
                )
                getAllAwaitingPermits()
            })
            .catch(error => {
                console.log(error)
            })
    }

    const sendPaymentsApproval = (permitId) => {
        const token = localStorage.getItem("token")
        axios.put(`permit/year/approve/${permitId}/?token=${token}`)
            .then(res => {
                Swal.fire(
                    "Success!",
                    "Approved with success and email will be sent to the company",
                    "success"
                )
                getAllAwaitingPermits()
            })
            .catch(error => {
                console.log(error)
            })
    }

    const approvePermit = (permitId) => {
        Swal.fire({
            title: "Initial Approval",
            html: "Are you sure you want to approve this permit? <br/>" +
                "(An email will be sent to the company email)",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Approve"
        })
            .then((res) => {
                if (res.isConfirmed) {
                    sendApproval(permitId)
                } else {
                    Swal.fire(
                        "Info!",
                        "The permit is not approved",
                        "info"
                    )
                }
            })
    }

    const approvePayment = (permitId) => {
        Swal.fire({
            title: "Final Approval",
            html: "Are you sure you want to approve this permit? <br/>" +
                "(An email will be sent to the company email including licence)",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Approve"
        })
            .then((res) => {
                if (res.isConfirmed) {
                    sendPaymentsApproval(permitId)
                } else {
                    Swal.fire(
                        "Info!",
                        "The permit is not approved",
                        "info"
                    )
                }
            })
    }

    const openModal = (state) => {
        state(true);
    };

    const closeModal = (state) => {
        state(false);
    };

    return (
        <React.Fragment>
            <DenyPopUp showPopUp={showDenyPopup} onClose={() => { closeModal(setShowDenyPopup) }} permitId={permitId} refreshData={getAllAwaitingPermits} />
            <PaymentDetails data={companyData} closeModal={() => { closeModal(setShowPaymentInfo) }} showPopUp={showPaymentInfo} />
            <DetailsPopUp data={companyData} url={apiBaseUrl.replace('/api/v1', '')} approvePermit={approvePermit} type={"One Year"} showPopUp={showInfoPopup} closeModal={() => { closeModal(setShowInfoPopup) }} />
            <div className=' flex flex-col items-center w-screen py-10 space-y-10'>
                <div className=' flex flex-row w-10/12'>
                    <div className=' w-full'>
                        <p className=' p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Pending Yearly Permits</p>
                    </div>
                </div>
                <div className="overflow-x-auto w-10/12">
                    <table className="table table-zebra">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Company Name</th>
                                {/* <th>Event Title</th> */}
                                <th>Expiration Date</th>
                                <th>Submition Date</th>
                                <th>Permit Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                permitsData.filter((permit) => permit.status === "Awaiting").map((permit) => (
                                    <tr key={permit.id}>
                                        <th>{permit.id}</th>
                                        <td>{permit.company.name}</td>
                                        {/* <td>{permit.event.name}</td> */}
                                        <td>{moment(permit.company.expirationDate).format("MMM Do YYYY")}</td>
                                        <td>{moment(permit.createdAt).format("MMM Do YYYY")}</td>
                                        <td>{permit.status}</td>
                                        <td>
                                            <div className=' flex flex-row space-x-3' >
                                                <div className='tooltip hover:cursor-pointer' data-tip="Permit Details" onClick={() => { setCompanyData(permit); openModal(setShowInfoPopup); setPermitType("One Day") }}>
                                                    <HiInformationCircle size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                                                </div>
                                                {
                                                    position !== "User" &&
                                                    <div className='tooltip hover:cursor-pointer' data-tip="Approve Permit" onClick={() => { approvePermit(permit.id) }}>
                                                        <TiTick size={24} color='#4F8E54' className=' bg-[#4f8e5469] rounded-md' />
                                                    </div>}
                                                {
                                                    position !== "User" &&
                                                    <div className='tooltip hover:cursor-pointer' data-tip="Deny Permit" onClick={() => { setPermitType("One Year"); setPermitId(permit.id); openModal(setShowDenyPopup) }}>
                                                        <TiCancel size={24} color='#8E4F4F' className=' bg-[#8E4F4F69] rounded-md' />
                                                    </div>}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className=' flex flex-row justify-center w-10/12 space-x-6'>
                    <div className=' flex flex-col w-1/2'>
                        <div className=' flex flex-row w-full'>
                            <div className=' w-full'>
                                <p className=' p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Initial Approved Permits</p>
                            </div>
                        </div>
                        <div className="overflow-x-auto w-full">
                            <table className="table table-zebra">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Company Name</th>
                                        {/* <th>Event Title</th> */}
                                        <th>Expiration Date</th>
                                        <th>Init Approve Date</th>
                                        <th>Permit Status</th>
                                        {/* <th>Permit Type</th> */}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        permitsData.filter((permit) => permit.status === "Init Approval").map((permit) => (
                                            <tr key={permit.id}>
                                                <th>{permit.id}</th>
                                                <td>{permit.company.name}</td>
                                                {/* <td>{permit.event.name}</td> */}
                                                <td>{moment(permit.company.expirationDate).format("MMM Do YYYY")}</td>
                                                <td>{moment(permit.updatedAt).format("MMM Do YYYY")}</td>
                                                <td>{permit.status}</td>
                                                {/* <td>{permit.type}</td> */}
                                                <td>
                                                    <div className=' flex flex-row space-x-3' >
                                                        <div className='tooltip hover:cursor-pointer' data-tip="Permit Details" onClick={() => { setCompanyData(permit); openModal(setShowInfoPopup); setPermitType("One Day") }}>
                                                            <HiInformationCircle size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                                                        </div>
                                                        {
                                                            permit.paymentInfo && position != "User" &&
                                                            <div className='tooltip hover:cursor-pointer' data-tip="Payment Details" onClick={() => { setCompanyData(permit); openModal(setShowPaymentInfo); }}>
                                                                <HiCurrencyDollar size={24} color='#1D95A3' className=' bg-[#1D95A369] rounded-md' />
                                                            </div>
                                                        }
                                                        {
                                                            (permit.status === "Init Approval") && position != "User" ?
                                                                <div className='tooltip hover:cursor-pointer' data-tip="Approve Payment" onClick={() => { approvePayment(permit.id) }}>
                                                                    <TiTick size={24} color='#4F8E54' className=' bg-[#4f8e5469] rounded-md' />
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className=' flex flex-col w-1/2'>
                        <div className=' flex flex-row w-full'>
                            <div className=' w-full'>
                                <p className=' p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Denied Permits</p>
                            </div>
                        </div>
                        <div className="overflow-x-auto w-full">
                            <table className="table table-zebra">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Company Name</th>
                                        {/* <th>Event Title</th> */}
                                        <th>Expiration Date</th>
                                        <th>Submition Date</th>
                                        <th>Permit Status</th>
                                        {/* <th>Permit Type</th> */}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        permitsData.filter((permit) => permit.status === "Denied").map((permit) => (
                                            <tr key={permit.id}>
                                                <th>{permit.id}</th>
                                                <td>{permit.company.name}</td>
                                                {/* <td>{permit.event.name}</td> */}
                                                <td>{moment(permit.company.expirationDate).format("MMM Do YYYY")}</td>
                                                <td>{moment(permit.updatedAt).format("MMM Do YYYY")}</td>
                                                <td>{permit.status}</td>
                                                {/* <td>{permit.type}</td> */}
                                                <td>
                                                    <div className=' flex flex-row space-x-3' >
                                                        <div className='tooltip hover:cursor-pointer' data-tip="Permit Details" onClick={() => { setCompanyData(permit); openModal(setShowInfoPopup); setPermitType("One Day") }}>
                                                            <HiInformationCircle size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                                                        </div>
                                                        {
                                                            (permit.status === "Denied") && position != "User" ?
                                                                <div className='tooltip hover:cursor-pointer' data-tip="Approve Permit" onClick={() => { approvePermit(permit.id) }}>
                                                                    <TiTick size={24} color='#4F8E54' className=' bg-[#4f8e5469] rounded-md' />
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className=' flex flex-row w-10/12'>
                    <div className=' w-full'>
                        <p className=' p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Approved Yearly Permits</p>
                    </div>
                </div>
                <div className="overflow-x-auto w-10/12">
                    <table className="table table-zebra">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Company Name</th>
                                {/* <th>Event Title</th> */}
                                <th>Expiration Date</th>
                                <th>Submition Date</th>
                                <th>Permit Status</th>
                                {/* <th>Permit Type</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                permitsData.filter((permit) => permit.status === "Active" && ((moment(permit.company.expirationDate).isAfter(moment(), 'day')))).map((permit) => (
                                    <tr key={permit.id}>
                                        <th>{permit.id}</th>
                                        <td>{permit.company.name}</td>
                                        {/* <td>{permit.event.name}</td> */}
                                        <td>{moment(permit.company.expirationDate).format("MMM Do YYYY")}</td>
                                        <td>{moment(permit.updatedAt).format("MMM Do YYYY")}</td>
                                        <td>{permit.status}</td>
                                        {/* <td>{permit.type}</td> */}
                                        <td>
                                            <div className=' flex flex-row space-x-3' >
                                                <div className='tooltip hover:cursor-pointer' data-tip="Permit Details" onClick={() => { setCompanyData(permit); openModal(setShowInfoPopup); setPermitType("One Day") }}>
                                                    <HiInformationCircle size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                                                    {/* <DetailsModel raf={detailsModelRef} data={permit} url={apiBaseUrl.replace('/api/v1', '')} /> */}
                                                </div>
                                                {
                                                    permit.paymentInfo && position != "User" &&
                                                    <div className='tooltip hover:cursor-pointer' data-tip="Payment Details" onClick={() => { setCompanyData(permit); openModal(setShowPaymentInfo); }}>
                                                        <HiCurrencyDollar size={24} color='#1D95A3' className=' bg-[#1D95A369] rounded-md' />
                                                    </div>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className=' flex flex-row w-10/12'>
                    <div className=' w-full'>
                        <p className=' p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Expired Yearly Permits</p>
                    </div>
                </div>
                <div className="overflow-x-auto w-10/12">
                    <table className="table table-zebra">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Company Name</th>
                                {/* <th>Event Title</th> */}
                                <th>Expiration Date</th>
                                <th>Submition Date</th>
                                <th>Permit Status</th>
                                {/* <th>Permit Type</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                permitsData.filter((permit) => permit.status === "Active" && (moment().diff(moment(permit.company.expirationDate).subtract(7, "d")) > 0)).map((permit) => (
                                    <tr key={permit.id}>
                                        <th>{permit.id}</th>
                                        <td>{permit.company.name}</td>
                                        {/* <td>{permit.event.name}</td> */}
                                        <td>{moment(permit.company.expirationDate).format("MMM Do YYYY")}</td>
                                        <td>{moment(permit.updatedAt).format("MMM Do YYYY")}</td>
                                        <td>
                                            {
                                                (moment(permit.company.expirationDate).isAfter())
                                                    ? "Pre-expired"
                                                    : "Expired"
                                            }
                                        </td>
                                        <td>
                                            <div className=' flex flex-row space-x-3' >
                                                <div className='tooltip hover:cursor-pointer' data-tip="Permit Details" onClick={() => { setCompanyData(permit); openModal(setShowInfoPopup); setPermitType("One Day") }}>
                                                    <HiInformationCircle size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                                                    {/* <DetailsModel raf={detailsModelRef} data={permit} url={apiBaseUrl.replace('/api/v1', '')} /> */}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default YearlyPermitsList