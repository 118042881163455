import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Swiper, SwiperSlide } from 'swiper/react';
import { TiCancel, TiDelete } from 'react-icons/ti'

// Import Swiper styles
import 'swiper/css';

const MainWebsite = () => {

    const [currentData, setCurrentData] = useState({})
    const [apiBaseUrl, setApiBaseUrl] = useState('') // Initialize as an empty string
    const [newVideo, setNewVideo] = useState(null)
    const [newVideoLink, setNewVideoLink] = useState('')
    const [NewBannerLink, setNewBannerLink] = useState('')
    const [bannerWebLink, setBannerWebLink] = useState('')
    const [bannerState, setBannerState] = useState(false)
    const [petroglyphLink, setPetroglyphLink] = useState("")
    const [petroglyphFile, setPetroglyphFile] = useState(null)
    const [fujairahGuideFile, setFujairahGuideFile] = useState(null)
    const [fujairahGuideLink, setFujairahGuideLink] = useState("")
    const [licenseVideo, setLicenseVideo] = useState(null)
    const [licenseVideoLink, setLicenseVideoLink] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [location, setLocation] = useState("")
    const [googlePlayLink, setGooglePlayLink] = useState("")
    const [appStoreLink, setAppStoreLink] = useState("")

    const getMainWebsiteData = () => {
        axios.get("website/info")
            .then(res => {
                setCurrentData(res.data)
                console.log(res.data)
                setApiBaseUrl((axios.defaults.baseURL).replace('/api/v1', '')) // Set the API base URL
            })
            .catch(err => {
                Swal.fire(
                    "Error!",
                    `${err.response.data.message}`,
                    "error"
                )
            })
    }

    const uploadFiles = async (file, setState) => {
        const formData = new FormData();
        formData.append('userFile', file); // Use the same field name as in your Express route

        try {
            const response = await axios.post('upload/upload_file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
                },
            });
            setState(response.data)

            console.log('File uploaded successfully:', response.data);
        } catch (error) {
            Swal.fire(
                "Error!",
                `${err.response.data.message}`,
                "error"
            )
        }
    }

    const uploadBanner = async (file) => {
        const formData = new FormData();
        formData.append('userFile', file); // Use the same field name as in your Express route

        try {
            const response = await axios.post('upload/upload_file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
                },
            });
            setNewBannerLink(response.data)
            setBannerWebLink(response.data)

            console.log('File uploaded successfully:', response.data);
        } catch (error) {
            Swal.fire(
                "Error!",
                `${err.response.data.message}`,
                "error"
            )
        }
    }

    const updateVideo = () => {
        axios.put("website/update/hero", { heroVideo: newVideoLink })
            .then(res => {
                Swal.fire({
                    title: "Success",
                    text: "Updated with success Page will reload in 1s",
                    icon: "success",
                    timer: 1000
                })
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            })
            .catch(err => {
                Swal.fire(
                    "Error!",
                    `${err.response.data.message}`,
                    "error"
                )
            })
    }

    const updatePetroglyph = () => {
        axios.put("website/update", { petroglyphsLink: petroglyphLink })
            .then(res => {
                Swal.fire({
                    title: "Success",
                    text: "Updated with success Page will reload in 1s",
                    icon: "success",
                    timer: 1000
                })
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            })
            .catch(err => {
                Swal.fire(
                    "Error!",
                    `${err.response.data.message}`,
                    "error"
                )
            })
    }

    const updateFujairahGuide = () => {
        axios.put("website/update", { fujairahGuide: fujairahGuideLink })
            .then(res => {
                Swal.fire({
                    title: "Success",
                    text: "Updated with success Page will reload in 1s",
                    icon: "success",
                    timer: 1000
                })
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            })
            .catch(err => {
                Swal.fire(
                    "Error!",
                    `${err.response.data.message}`,
                    "error"
                )
            })
    }

    const updateLicenseVideo = () => {
        axios.put("website/update", { licenseVideo: licenseVideoLink })
            .then(res => {
                Swal.fire({
                    title: "Success",
                    text: "Updated with success Page will reload in 1s",
                    icon: "success",
                    timer: 1000
                })
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            })
            .catch(err => {
                Swal.fire(
                    "Error!",
                    `${err.response.data.message}`,
                    "error"
                )
            })
    }

    const updateOtherInfos = () => {
        const data = {
            phoneNumber: phoneNumber === "" ? currentData.phoneNumber : phoneNumber,
            email: email === "" ? currentData.email : email,
            location: location === "" ? currentData.location : location,
            googlePlay: googlePlayLink === "" ? currentData.googlePlay : googlePlayLink,
            appStore: appStoreLink === "" ? currentData.appStore : appStoreLink
        }
        axios.put("website/update", data)
            .then(res => {
                Swal.fire({
                    title: "Success",
                    text: "Updated with success Page will reload in 1s",
                    icon: "success",
                    timer: 1000
                })
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            })
            .catch(err => {
                Swal.fire(
                    "Error!",
                    `${err.response.data.message}`,
                    "error"
                )
            })
    }

    const updateBanner = () => {
        if (newVideoLink !== '' || bannerWebLink !== '') {
            const array = [
                { link: bannerWebLink, imageLink: NewBannerLink }
            ];
    
            let uploadArray;
    
            if (currentData && Array.isArray(currentData.bannersList)) {
                uploadArray = [...currentData.bannersList, ...array];
            } else {
                uploadArray = [...array];
            }
    
            axios.put("website/update/banner", { bannersList: uploadArray })
                .then(res => {
                    Swal.fire({
                        title: "Success",
                        text: "Updated with success Page will reload in 1s",
                        icon: "success",
                        timer: 1000
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch(err => {
                    Swal.fire(
                        "Error!",
                        `${err.response.data.message}`,
                        "error"
                    );
                });
        } else {
            Swal.fire(
                'Error!',
                "All Fields Are required",
                "error"
            );
        }
    };
    

    const deleteBanner = (bannerIndex) => {
        let uploadArray;
        let currentArray;
        currentArray = currentData.bannersList
        uploadArray = currentArray.splice(bannerIndex, 1)
        axios.put("website/update/banner", { bannersList: uploadArray })
            .then(res => {
                Swal.fire({
                    title: "Success",
                    text: "Updated with success Page will reload in 1s",
                    icon: "success",
                    timer: 1000
                })
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            })
            .catch(err => {
                Swal.fire(
                    "Error!",
                    `${err.response.data.message}`,
                    "error"
                )
            })
    }

    useEffect(() => {
        getMainWebsiteData()
    }, [])

    return (
        <React.Fragment>
            <div className='flex flex-col items-center w-screen py-10 space-y-10'>
                <div className='flex flex-row w-10/12'>
                    <div className='w-full'>
                        <p className='p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Hero section Video</p>
                    </div>
                </div>
                <div className='flex flex-col w-10/12 items-center'>
                    {
                        currentData
                            ? (
                                currentData.heroVideo !== undefined && currentData.heroVideo !== null
                                    ? (
                                        <video controls className="w-8/12 h-10/12 object-cover rounded-3xl">
                                            <source src={`${apiBaseUrl}${currentData.heroVideo}`} type="video/mp4" />
                                        </video>
                                    )
                                    : <p>No video available</p>
                            )
                            : <p>Loading...</p>
                    }



                </div>
                <div className=' flex flex-row items-center space-x-5'>
                    <input type="file" onChange={(e) => { setNewVideo(e.target.files[0]); uploadFiles(e.target.files[0], setNewVideoLink) }} accept='video/mp4' className="file-input file-input-bordered w-full max-w-xs" />
                    {
                        newVideo !== null && newVideoLink !== "" &&
                        <button className=' bg-[#d2b199c6] p-3 rounded-xl px-10 font-semibold' onClick={() => { updateVideo() }}>
                            Update Now
                        </button>
                    }
                </div>
                <div className='flex flex-row justify-between w-10/12 p-4 bg-[#DCC3B1] rounded-xl px-20'>
                    <div className='w-full'>
                        <p className='text-xl font-semibold capitalize'>Banners List</p>
                    </div>
                    <div className=' flex flex-row space-x-4'>
                        <p>update</p>
                        <input type="checkbox" className="toggle" value={bannerState} onChange={(e) => { setBannerState(e.target.checked) }} />
                        <p>delete</p>
                    </div>
                </div>
                {
                    !bannerState ?
                        <>
                            <div className=' flex flex-col w-10/12 items-center'>
                                {
                                    currentData && currentData.bannersList &&
                                    <Swiper
                                        slidesPerView={1}
                                        loop={true}
                                        onSlideChange={() => console.log('slide change')}
                                        onSwiper={(swiper) => console.log(swiper)}
                                        className='w-8/12 h-10/12 object-cover rounded-3xl'
                                    >
                                        {
                                            currentData && Array.isArray(currentData.bannersList) && currentData.bannersList.map(banner => (
                                                <SwiperSlide key={banner.id}>
                                                    <a href={banner.link} target='_blank'>
                                                        <img src={apiBaseUrl + banner.imageLink} alt="" />
                                                    </a>
                                                </SwiperSlide>
                                            ))
                                        }


                                    </Swiper>
                                }


                            </div>
                            <div className=' flex flex-row items-center w-10/12 justify-center space-x-5'>
                                <input type="file" onChange={(e) => { uploadBanner(e.target.files[0]) }} accept='image/jpg, image/png' className="file-input file-input-bordered w-full max-w-xs" />
                                <input type="text" onChange={(e) => { setBannerWebLink(e.target.value) }} placeholder="Banner Link" className="input input-bordered w-full max-w-xs" />
                                {
                                    (bannerWebLink !== '' && NewBannerLink !== '') ?
                                        <button className=' bg-[#d2b199c6] p-3 rounded-xl px-10 font-semibold' onClick={() => { updateBanner() }}>
                                            Update Now
                                        </button>
                                        :
                                        null
                                }
                            </div>
                        </>
                        :
                        <>
                            <div className='flex flex-col w-10/12 items-center'>
                                <div className=' grid grid-cols-3 gap-5'>
                                    {
                                        currentData && currentData.bannersList &&
                                        (currentData.bannersList).map((banner, index) => (
                                            <div className=' relative'>
                                                <div className=' absolute -top-5 -right-5' onClick={() => { deleteBanner(index) }}>
                                                    <TiDelete color='#8E4F4F' size={48} />
                                                </div>
                                                <img src={apiBaseUrl + banner.imageLink} className=' w-96 h-96 object-cover' alt="" />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </>
                }
                <div className='flex flex-row w-10/12'>
                    <div className='w-full'>
                        <p className='p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Petroglyph Link</p>
                    </div>
                </div>
                <div className=' flex flex-col items-center space-y-5'>
                    {
                        currentData && currentData.petroglyphsLink ?
                            <a href={apiBaseUrl + currentData.petroglyphsLink} target='_blank'>
                                View Current
                            </a>
                            : null
                    }

                    <input type="file" placeholder={currentData} onChange={(e) => { setPetroglyphFile(e.target.files[0]); uploadFiles(e.target.files[0], setPetroglyphLink) }} className="file-input file-input-bordered w-full max-w-xs" />
                    {
                        petroglyphFile !== null && petroglyphLink !== "" &&
                        <button className=' bg-[#d2b199c6] p-3 rounded-xl px-10 font-semibold' onClick={() => { updatePetroglyph() }}>
                            Update Now
                        </button>
                    }
                </div>
                <div className='flex flex-row w-10/12'>
                    <div className='w-full'>
                        <p className='p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Fujairah Guide</p>
                    </div>
                </div>
                <div className=' flex flex-col items-center space-y-5'>
                    {
                        currentData && currentData.fujairahGuide ?
                            <a href={apiBaseUrl + currentData.fujairahGuide} target='_blank'>
                                View Current
                            </a>
                            : null
                    }
                    <input type="file" placeholder={currentData} onChange={(e) => { setFujairahGuideFile(e.target.files[0]); uploadFiles(e.target.files[0], setFujairahGuideLink) }} className="file-input file-input-bordered w-full max-w-xs" />
                    {
                        fujairahGuideFile !== null && fujairahGuideLink !== "" &&
                        <button className=' bg-[#d2b199c6] p-3 rounded-xl px-10 font-semibold' onClick={() => { updateFujairahGuide() }}>
                            Update Now
                        </button>
                    }
                </div>
                <div className='flex flex-row w-10/12'>
                    <div className='w-full'>
                        <p className='p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>License Video</p>
                    </div>
                </div>
                <div className=' flex flex-col items-center space-y-5'>
                    {
                        currentData && currentData.licenseVideo ?
                            <a href={apiBaseUrl + currentData.licenseVideo} target='_blank'>
                                View Current
                            </a>
                            : null
                    }
                    <input type="file" placeholder={currentData} onChange={(e) => { setLicenseVideo(e.target.files[0]); uploadFiles(e.target.files[0], setLicenseVideoLink) }} className="file-input file-input-bordered w-full max-w-xs" />
                    {
                        licenseVideo !== null && licenseVideoLink !== "" &&
                        <button className=' bg-[#d2b199c6] p-3 rounded-xl px-10 font-semibold' onClick={() => { updateLicenseVideo() }}>
                            Update Now
                        </button>
                    }
                </div>
                <div className='flex flex-row w-10/12'>
                    <div className='w-full'>
                        <p className='p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>Other Informations</p>
                    </div>
                </div>
                <div className=' grid grid-cols-2 gap-6 w-10/12'>
                    <div className=' flex flex-col space-y-2'>
                        <label htmlFor="emailAddress">Email Address</label>
                        <input id='emailAddress' placeholder={currentData && currentData.email} type="text" onChange={(e) => { setEmail(e.target.value) }} className='input input-bordered' />
                    </div>
                    <div className=' flex flex-col space-y-2'>
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input id='phoneNumber' placeholder={currentData && currentData.phoneNumber} type="text" onChange={(e) => { setPhoneNumber(e.target.value) }} className='input input-bordered' />
                    </div>
                    <div className=' flex flex-col space-y-2'>
                        <label htmlFor="Location">Location</label>
                        <input id='Location' placeholder={currentData && currentData.location} type="text" onChange={(e) => { setLocation(e.target.value) }} className='input input-bordered' />
                    </div>
                    <div className=' flex flex-col space-y-2'>
                        <label htmlFor="googlePlay">Google Play Link</label>
                        <input id='googlePlay' type="text" placeholder={currentData && currentData.googlePlay} onChange={(e) => { setGooglePlayLink(e.target.value) }} className='input input-bordered' />
                    </div>
                    <div className=' flex flex-col space-y-2'>
                        <label htmlFor="appStoreLink">App Store Link</label>
                        <input id='appStoreLink' type="text" placeholder={currentData && currentData.appStore} onChange={(e) => { setAppStoreLink(e.target.value) }} className='input input-bordered' />
                    </div>
                </div>
                <button className=' bg-[#d2b199c6] p-3 rounded-xl px-10 font-semibold' onClick={() => { updateOtherInfos() }}>
                    Update Now
                </button>
            </div>
        </React.Fragment>
    )
}

export default MainWebsite
