import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { HiInformationCircle } from 'react-icons/hi2'
import { TiCancel, TiTick } from 'react-icons/ti'
import { useNavigate } from 'react-router'
import Swal from 'sweetalert2'
import InfoPopUp from './InfoPopUp'

const CompaniesList = () => {

    const navigate = useNavigate()

    const detailsModelRef = useRef(null);

    const openDetailsModal = () => {
        if (detailsModelRef.current) {
            detailsModelRef.current.showModal();
        }
    };

    const [companiesData, setCompaniesData] = useState([])
    const [apiBaseUrl, setApiBaseUrl] = useState(axios.defaults.baseURL)
    const [companyData, setCompanyData] = useState({})
    const [showInfoPopup, setShowInfoPopup] = useState(false)

    const openModal = (state) => {
        state(true);
    };

    const closeModal = (state) => {
        state(false);
    };


    const getAllCompanies = () => {
        const token = localStorage.getItem("token")
        axios.get(`permit/companies/?token=${token}`)
            .then(res => {
                setCompaniesData(res.data)
            })
            .catch(error => {
                console.log(error)
                Swal.fire(
                    "Error!",
                    `${error.response.data}`,
                    "error"
                )
                navigate("/login", { replace: true })
            })
    }

    useEffect(() => {
        getAllCompanies();
        // console.log(axios.defaults.baseURL)
    }, [])

    return (
        <React.Fragment>
            <InfoPopUp data={companyData} closeModal={() => { closeModal(setShowInfoPopup) }} showPopUp={showInfoPopup} />
            <div className=' flex flex-col items-center w-screen py-10 space-y-10'>
                <div className=' flex flex-row w-10/12'>
                    <div className=' w-full'>
                        <p className=' p-4 bg-[#DCC3B1] rounded-xl text-xl font-semibold capitalize'>All registred Companies</p>
                    </div>
                </div>
                <div className="overflow-x-auto w-10/12">
                    <table className="table table-zebra">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Company Name</th>
                                <th>Activity Type</th>
                                <th>Expiration Date</th>
                                <th>Submition Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                companiesData &&
                                companiesData.map((company) => (
                                    <tr key={company.id}>
                                        <th>{company.id}</th>
                                        <td>{company.name}</td>
                                        <td>{company.activityType}</td>
                                        <td>{moment(company.expirationDate).format("MMM Do YYYY")}</td>
                                        <td>{moment(company.createdAt).format("MMM Do YYYY")}</td>
                                        <td>
                                            <div className=' flex flex-row space-x-3' >
                                                <div className='tooltip hover:cursor-pointer' data-tip="Company Details" onClick={() => { setShowInfoPopup(true); setCompanyData(company) }}>
                                                    <HiInformationCircle size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                                                    {/* <DetailsModel raf={detailsModelRef} data={companyData} url={apiBaseUrl.replace('/api/v1', '')} /> */}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CompaniesList


export const DetailsModel = ({ url, data, raf }) => {

    let authorities;

    if (data) {
        const authoritiesArray = JSON.parse(data?.issuingAuthority)
        const authorityTitles = (authoritiesArray).map(activity => activity.title);
        authorities = authorityTitles.join(', ')
        console.log(data)
    } else {
        console.error('Data or event is null or undefined');
    }

    return (
        <dialog className="modal" ref={raf}>
            {
                data &&
                <form method="dialog" className="modal-box w-11/12 max-w-5xl items-start">
                    <h3 className='text-2xl font-semibold text-left'>Company Informations</h3>
                    <div className=' divider'></div>
                    <div className=' grid grid-cols-3 gap-5'>
                        <div className=' flex flex-col space-y-2'>
                            <h3 className=' text-left'>Name</h3>
                            <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                {data.name}
                            </div>
                        </div>
                        <div className=' flex flex-col space-y-2'>
                            <h3 className=' text-left'>License Number</h3>
                            <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                {data.licenseNumber}
                            </div>
                        </div>
                        <div className=' flex flex-col space-y-2'>
                            <h3 className=' text-left'>Issuing Authority</h3>
                            <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                {authorities}
                            </div>
                        </div>
                        <div className=' flex flex-col space-y-2'>
                            <h3 className=' text-left'>Contact Number</h3>
                            <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                {data.contactNumber}
                            </div>
                        </div>
                        <div className=' flex flex-col space-y-2'>
                            <h3 className=' text-left'>Contact Email</h3>
                            <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                {data.contactEmail}
                            </div>
                        </div>
                        <div className=' flex flex-col space-y-2'>
                            <h3 className=' text-left'>Expiration Date</h3>
                            <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                {moment(data.expirationDate).format("MMMM Do YYYY")}
                            </div>
                        </div>
                    </div>
                    <div className="modal-action">
                        {/* if there is a button, it will close the modal */}
                        <button className="btn bg-[#8E4F4F69] text-[#8E4F4F]">Close</button>
                    </div>
                </form>
            }
        </dialog>
    )
}