import axios from 'axios';
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const Callback = () => {

    const location = useLocation();
    console.log(new URLSearchParams(location.search).get('code'))
    const code = new URLSearchParams(location.search).get('code');
    localStorage.setItem("code", code)

    const getInfo = () => {
        axios.get(`https://api.fujairahadventures.ae/auth/google/callback/?code=${code}`)
        .then(res => {
            console.log(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
      if (code) {
        getInfo()
      }
    }, [])
    

    return (
        <div className=' h-screen flex flex-col justify-center items-center text-6xl'>
            You'll be redirected soon ...
        </div>
    )
}

export default Callback