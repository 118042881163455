import React from 'react';
import App from './app';
import './app.css';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

axios.defaults.baseURL = "https://api.fujairahadventures.ae/api/v1/"
// axios.defaults.baseURL = "http://localhost:8800/api/v1/"



const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <BrowserRouter>
        <App tab="home" />
    </BrowserRouter>
);