import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { HiInformationCircle } from 'react-icons/hi2'
import { TiCancel, TiTick } from 'react-icons/ti'
import { useNavigate } from 'react-router'
import Swal from 'sweetalert2'
import UserDetails from './UserDetails'

const UsersList = () => {

    const navigate = useNavigate()

    const detailsModelRef = useRef(null);

    const openDetailsModal = () => {
        if (detailsModelRef.current) {
            detailsModelRef.current.showModal();
        }
    };

    const [usersData, setUsersData] = useState({ users: [], formUsers: [] })
    const [apiBaseUrl, setApiBaseUrl] = useState(axios.defaults.baseURL)
    const [showInfoPopup, setShowInfoPopup] = useState(false)
    const [userDataPlus, setUserDataPlus] = useState(null)
    const [formUsers, setFormUsers] = useState(true)


    const getAllUsers = () => {
        const token = localStorage.getItem("token")
        axios.get(`users/infos/?token=${token}`)
            .then(res => {
                setUsersData(res.data)
            })
            .catch(error => {
                console.log(error)
                Swal.fire(
                    "Error!",
                    `${error.response.data}`,
                    "error"
                )
                navigate("/login", { replace: true })
            })
    }

    const openModal = (state) => {
        state(true);
    };

    const closeModal = (state) => {
        state(false);
    };

    const deleteUser = (id) => {
        console.log(id)
        const token = localStorage.getItem("token")
        Swal.fire({
            title: "Are You Sure?",
            text: "This User will be deleted permanently",
            showCancelButton: true,
        })
            .then(res => {
                if (res.isConfirmed) {
                    axios.delete(`users/admin/delete/?token=${token}&id=${id}`, { id: id })
                        .then(res => {
                            getAllUsers();
                            Swal.fire({
                                title: "Success!",
                                icon: 'success',
                                text: "User Deleted With Success",
                            })
                        })
                        .catch(error => {
                            console.log(error)
                            Swal.fire(
                                "Error!",
                                `${error.response.data}`,
                                "error"
                            )
                            navigate("/login", { replace: true })
                        })
                } else {
                    Swal.fire(
                        "Info!",
                        `Action is cancelled`,
                        "info"
                    )
                }
            })
    }
    const isArray = (arr) => Array.isArray(arr);

    useEffect(() => {
        getAllUsers();
        // console.log(axios.defaults.baseURL)
    }, [])

    return (
        <React.Fragment>
            <UserDetails closeModal={() => { closeModal(setShowInfoPopup) }} showPopUp={showInfoPopup} data={userDataPlus} />
            <div className=' flex flex-col items-center w-screen py-10 space-y-10'>
                <div className=' flex flex-row w-10/12 bg-[#DCC3B1] p-4  rounded-xl'>
                    <div className=' w-full'>
                        <p className=' text-xl font-semibold capitalize'>All registred Users</p>
                    </div>
                    <div className=' flex flex-row space-x-4 w-4/12'>
                        <p>Registred By Form</p>
                        <input type="checkbox" className="toggle" value={formUsers} onChange={() => { setFormUsers(!formUsers) }} />
                        <p>Normal Registration</p>
                    </div>
                </div>
                <div className="overflow-x-auto w-10/12">
                    {
                        formUsers === true ?
                            <table className="table table-zebra">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Full Name</th>
                                        <th>E-mail address</th>
                                        <th>Gender</th>
                                        <th>Birth Date</th>
                                        <th>Address</th>
                                        <th>ID Link</th>
                                        <th>Phone Number</th>
                                        <th>Country</th>
                                        {/* <th>Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isArray(usersData.formUsers) && usersData.formUsers.length > 0 ?
                                            usersData.formUsers.map((user) => (
                                                <tr key={user.id} className=' items-center'>
                                                    <th>{user.id}</th>
                                                    <td>{user.fullName}</td>
                                                    <td>{user.email}</td>
                                                    <td>{user.gender}</td>
                                                    <td>{moment(user.birthDate).format("MMM Do YYYY")}</td>
                                                    <td>{user.address}</td>
                                                    <td><a href={apiBaseUrl.replace('api/v1/', '') + user.emiratesID} target='_blank'>Link</a></td>
                                                    <td>{user.phoneNumber}</td>
                                                    <td>{user.country}</td>
                                                    {/* <td className=' flex flex-row items-center justify-center space-x-4'>
                                                    {
                                                        user.uaePassInfo ?
                                                            <div className='tooltip hover:cursor-pointer' data-tip="User Details" onClick={() => { setUserDataPlus(user.uaePassInfo); openModal(setShowInfoPopup); }}>
                                                                <HiInformationCircle size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                                                            </div>
                                                            : null
                                                    }
                                                    <div className='tooltip hover:cursor-pointer' data-tip="User Details" onClick={() => { deleteUser(user.id); }}>
                                                        <TiCancel size={24} color='#8E4F4F' className=' bg-[#8E4F4F69] rounded-md' />
                                                    </div>
                                                </td> */}
                                                </tr>
                                            )) :
                                            <tr>
                                                <td colSpan="9" className="text-center">
                                                    No users found
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table> :
                            <table className="table table-zebra">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Full Name</th>
                                        <th>E-mail address</th>
                                        <th>Registration Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isArray(usersData.users) && usersData.users.length > 0 ?
                                            usersData.users.map((user) => (
                                                <tr key={user.id} className=' items-center'>
                                                    <th>{user.id}</th>
                                                    <td>{user.fullName}</td>
                                                    <td>{user.email}</td>
                                                    <td>{moment(user.createdAt).format("MMM Do YYYY")}</td>
                                                    <td className=' flex flex-row items-center justify-center space-x-4'>
                                                        {
                                                            user.uaePassInfo ?
                                                                <div className='tooltip hover:cursor-pointer' data-tip="User Details" onClick={() => { setUserDataPlus(user.uaePassInfo); openModal(setShowInfoPopup); }}>
                                                                    <HiInformationCircle size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                                                                </div>
                                                                : null
                                                        }
                                                        <div className='tooltip hover:cursor-pointer' data-tip="User Details" onClick={() => { deleteUser(user.id); }}>
                                                            <TiCancel size={24} color='#8E4F4F' className=' bg-[#8E4F4F69] rounded-md' />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) :
                                            <tr>
                                                <td colSpan="5" className="text-center">
                                                    No users found
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default UsersList


export const DetailsModel = ({ url, data, raf }) => {

    return (
        <dialog className="modal" ref={raf}>
            <form method="dialog" className="modal-box w-11/12 max-w-5xl items-start">
                <h3 className='text-2xl font-semibold text-left'>Company Informations</h3>
                <div className=' divider'></div>
                <div className=' grid grid-cols-3 gap-5'>
                    <div className=' flex flex-col space-y-2'>
                        <h3 className=' text-left'>Name</h3>
                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                            {data.name}
                        </div>
                    </div>
                    <div className=' flex flex-col space-y-2'>
                        <h3 className=' text-left'>License Number</h3>
                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                            {data.licenseNumber}
                        </div>
                    </div>
                    <div className=' flex flex-col space-y-2'>
                        <h3 className=' text-left'>Activity Type</h3>
                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                            {data.activityType}
                        </div>
                    </div>
                    <div className=' flex flex-col space-y-2'>
                        <h3 className=' text-left'>Issuing Authority</h3>
                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                            {data.issuingAuthority}
                        </div>
                    </div>
                    <div className=' flex flex-col space-y-2'>
                        <h3 className=' text-left'>Contact Number</h3>
                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                            {data.contactNumber}
                        </div>
                    </div>
                    <div className=' flex flex-col space-y-2'>
                        <h3 className=' text-left'>Contact Email</h3>
                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                            {data.contactEmail}
                        </div>
                    </div>
                    <div className=' flex flex-col space-y-2'>
                        <h3 className=' text-left'>Expiration Date</h3>
                        <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                            {moment(data.expirationDate).format("MMMM Do YYYY")}
                        </div>
                    </div>
                </div>
                <div className="modal-action">
                    {/* if there is a button, it will close the modal */}
                    <button className="btn bg-[#8E4F4F69] text-[#8E4F4F]">Close</button>
                </div>
            </form>
        </dialog>
    )
}