import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react'

const ExportPdf = ({ showPopUp, onClose }) => {

    const [showModal, setShowModal] = useState(showPopUp);
    const [customDateRange, setCustomDateRange] = useState(false);
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [Loading, setLoading] = useState(false)
    const [reportType, setReportType] = useState("")

    const handleWeekClick = () => {
        const today = new Date();
        const dayOfWeek = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        const daysToLastSaturday = dayOfWeek === 0 ? 0 : dayOfWeek; // Adjust for Sunday
        const lastSaturday = new Date(today);
        lastSaturday.setDate(today.getDate() - daysToLastSaturday);
        setStartDate(lastSaturday);
        setEndDate(new Date())
    };

    const handleMonthClick = () => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        setStartDate(firstDayOfMonth);
        setEndDate(new Date())
    };

    const handleYearClick = () => {
        const today = new Date();
        const firstDayOfYear = new Date(today.getFullYear(), 0, 1); // January 1st of the current year
        setStartDate(firstDayOfYear);
        setEndDate(new Date())
    };

    useEffect(() => {
        setShowModal(showPopUp);
    }, [showPopUp]);

    const closeModal = () => {
        setShowModal(false);
        if (onClose) {
            onClose();
        }
    };

    const exportPdf = async () => {
        setLoading(true)
        try {
            const response = await axios.post(`permit/export/pdf`, { minimumDate: startDate, maximumDate: endDate, reportType: reportType }, {
                responseType: 'blob', // Set the response type to blob
            });

            // Create a URL for the blob
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a link element
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.setAttribute('download', 'permit-report.pdf'); // Specify the filename

            // Append to the document, click it to trigger download, and remove it afterward
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            // Clean up the URL object
            window.URL.revokeObjectURL(url);

            setLoading(false);
            closeModal();
            setStartDate(null);
            setEndDate(null);
            setReportType("")
        } catch (error) {
            console.error('Error downloading PDF:', error);
            setLoading(false);
        }

    }


    return (
        <React.Fragment>
            <Transition appear show={showModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden space-y-4 rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-2xl font-medium leading-6 text-gray-900"
                                    >
                                        Export Report
                                    </Dialog.Title>
                                    <p className=' text-gray-400'>Select One of the bellow option to get data</p>
                                    <div className=' flex flex-col w-full space-y-10'>
                                        <div className='flex flex-row w-full relative justify-evenly'>
                                            <button className='p-2 px-8 rounded-lg bg-[#DCC2B0]' onClick={() => { setCustomDateRange(false); handleWeekClick() }}>Week</button>
                                            <button className='p-2 px-8 rounded-lg bg-[#DCC2B0]' onClick={() => { setCustomDateRange(false); handleMonthClick() }}>Month</button>
                                            <button className='p-2 px-8 rounded-lg bg-[#DCC2B0]' onClick={() => { setCustomDateRange(false); handleYearClick() }}>Year</button>
                                            <button className='p-2 px-8 rounded-lg bg-[#DCC2B0]' onClick={() => { setCustomDateRange(true) }}>Custom</button>
                                        </div>
                                        {
                                            customDateRange &&
                                            <div className=' flex flex-row w-full justify-evenly'>
                                                <div className='space-y-2 w-1/3'>
                                                    <p>Start Date:</p>
                                                    <input className='p-2 w-full border-2 rounded-lg border-black' type="date" onChange={(e) => { setStartDate(e.target.value) }} />
                                                </div>
                                                <div className='space-y-2 w-1/3'>
                                                    <p>End Date:</p>
                                                    <input className='p-2 w-full border-2 rounded-lg border-black' type="date" onChange={(e) => { setEndDate(e.target.value) }} />
                                                </div>
                                            </div>
                                        }
                                        {
                                            startDate && endDate &&
                                            <div className=' space-y-4'>
                                                <p>Select Report Type:</p>
                                                <select defaultValue={reportType} onChange={(e) => { setReportType(e.target.value) }} className=' p-2 border-[#DCC2B0] border-2 rounded-lg px-4 w-1/2'>
                                                    <option value="" disabled hidden>Select Report</option>
                                                    <option value="All">All Reports</option>
                                                    <option value="Daily">Daily Permits</option>
                                                    <option value="Yearly">Yearly Permits</option>
                                                    <option value="Parti">Events Participants</option>
                                                </select>
                                            </div>
                                        }

                                        {
                                            Loading &&
                                            <div className=' flex flex-row w-full justify-center items-center space-x-4'>
                                                {/* <p className=' text-xl font-bold'>Loading</p> */}
                                                <div class="flex items-center justify-center space-x-2">
                                                    <div class="w-4 h-4 bg-blue-500 rounded-full animate-pulse"></div>
                                                    <div class="w-4 h-4 bg-blue-500 rounded-full animate-pulse"></div>
                                                    <div class="w-4 h-4 bg-blue-500 rounded-full animate-pulse"></div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            startDate && endDate && reportType &&
                                            <button className='p-2 px-8 rounded-lg bg-[#DCC2B0]' onClick={() => { exportPdf(true) }}>Download Now</button>
                                        }

                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </React.Fragment>
    )
}

export default ExportPdf