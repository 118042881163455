import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { TiCancel } from 'react-icons/ti'
import { useNavigate } from 'react-router'
import Swal from 'sweetalert2'

const StaffsList = () => {
    const navigate = useNavigate()

    const createEventRef = useRef(null)

    const openCreateEventModal = () => {
        if (createEventRef.current) {
            createEventRef.current.showModal();
        }
    };

    const closeCreateEventModal = () => {
        if (createEventRef.current) {
            createEventRef.current.close();
        }
    };

    const [usersData, setUsersData] = useState([])
    const [apiBaseUrl, setApiBaseUrl] = useState(axios.defaults.baseURL)


    const getAllUsers = () => {
        const token = localStorage.getItem("token")
        axios.get(`admin/infos/?token=${token}`)
            .then(res => {
                setUsersData(res.data)
            })
            .catch(error => {
                console.log(error)
                Swal.fire(
                    "Error!",
                    `${error.response.data}`,
                    "error"
                )
                navigate("/login", { replace: true })
            })
    }

    const deleteStaff = (userId) => {
        const token = localStorage.getItem("token")
        axios.delete(`admin/delete/${userId}/?token=${token}`)
            .then(res => {
                console.log(res.data);
                getAllUsers()
            })
            .catch(err => {
                Swal.fire(
                    "Error!",
                    `${err.response.data.message}`,
                    "error"
                )
            })
    }

    useEffect(() => {
        getAllUsers();
        // console.log(axios.defaults.baseURL)
    }, [])

    return (
        <React.Fragment>
            <div className=' flex flex-col items-center w-screen py-10 space-y-10'>
                <div className=' flex flex-row w-10/12 p-4 bg-[#DCC3B1] rounded-xl items-center'>
                    <div className=' w-full'>
                        <p className=' text-xl font-semibold capitalize'>Staffs List</p>
                    </div>
                    <div>
                        <button className='btn bg-white' onClick={() => { openCreateEventModal() }} >Create Staff</button>
                        <CreateEventModal raf={createEventRef} getData={getAllUsers} closeModal={closeCreateEventModal} />
                    </div>
                </div>
                <div className="overflow-x-auto w-10/12">
                    <table className="table table-zebra">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Full Name</th>
                                <th>E-mail address</th>
                                <th>Position</th>
                                <th>Title</th>
                                <th>Registration Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                usersData.map((user) => (
                                    <tr key={user.id}>
                                        <th>{user.id}</th>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.position}</td>
                                        <td>{user.title}</td>
                                        <td>{moment(user.createdAt).format("MMM Do YYYY")}</td>
                                        <td>
                                            <div className=' flex flex-row space-x-3' >
                                                {/* <div className='tooltip hover:cursor-pointer' data-tip="Company Details" onClick={() => { openDetailsModal() }}>
                                                    <HiInformationCircle size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                                                    <DetailsModel raf={detailsModelRef} data={company} url={apiBaseUrl.replace('/api/v1', '')} />
                                                </div> */}
                                                <div className='tooltip hover:cursor-pointer' data-tip="Delete Event" onClick={() => { deleteStaff(user.id) }}>
                                                    <TiCancel size={24} color='#8E4F4F' className=' bg-[#8E4F4F69] rounded-md' />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default StaffsList


export const CreateEventModal = ({ raf, getData, closeModal }) => {

    const [Name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [Password, setPassword] = useState('')
    const [position, setPosition] = useState('')
    const [title, setTitle] = useState('')


    const updateData = (e, state) => {
        state(e.target.value)
    }

    const createEvent = () => {
        const datas = {
            email: email,
            password: Password,
            name: Name,
            position: position,
            title: title
        }
        const token = localStorage.getItem("token")

        axios.post(`admin/create/?token=${token}`, datas)
            .then(res => {
                console.log(res.data)
                closeModal()
                Swal.fire(
                    "Success!",
                    "Admin successfully created",
                    "success"
                )
                getData()
                setEmail("")
                setName("")
                setPassword("")
                setPosition("")
                setTitle("")
            })
            .catch(err => {
                closeModal()
                Swal.fire(
                    "Error!",
                    `${err.response.data.message}`,
                    "error"
                )
            })
    }

    return (
        <dialog className="modal" ref={raf}>
            <form method="dialog" className="modal-box w-11/12 max-w-5xl items-start">
                <div className=' grid grid-cols-2 gap-5'>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="FullName" className=' font-semibold text-lg'>Full Name</label>
                        <input id='FullName' type="text" value={Name} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setName) }} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="emailAddress" className=' font-semibold text-lg'>Email Address</label>
                        <input id='emailAddress' type="text" value={email} className='p-3 bg-[#A55B2730] rounded-md  text-left px-7' onChange={(e) => { updateData(e, setEmail) }} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="Password" className=' font-semibold text-lg'>Password</label>
                        <input id='Password' type="text" value={Password} className='p-3 bg-[#A55B2730] rounded-md  text-left px-7' onChange={(e) => { updateData(e, setPassword) }} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="Title" className=' font-semibold text-lg'>Title</label>
                        <input id='Title' type="text" value={title} className='p-3 bg-[#A55B2730] rounded-md  text-left px-7' onChange={(e) => { updateData(e, setTitle) }} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="Position" className=' font-semibold text-lg'>Position</label>
                        <select id='Position' onChange={(e) => { setPosition(e.target.value) }} className="select select-bordered bg-[#A55B2730] w-full">
                            <option value={""} disabled selected hidden>Position</option>
                            <option value={"Super Admin"}>Super Admin</option>
                            <option value={"Admin"}>Admin</option>
                            <option value={"User"}>User</option>
                        </select>
                    </div>
                </div>
                <div className="modal-action">
                    {/* if there is a button, it will close the modal */}
                    {
                        (Name && email && position && Password) ?
                            <div className="btn bg-[#4f8e5469] text-[#4f8e54]" onClick={() => { createEvent() }}>Create</div>
                            : null
                    }
                    <button className="btn bg-[#8E4F4F69] text-[#8E4F4F]">Close</button>
                </div>
            </form>
        </dialog>
    )
}