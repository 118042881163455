import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { HiInformationCircle } from 'react-icons/hi2'
import { TiCancel, TiTick } from 'react-icons/ti'
import Swal from 'sweetalert2'

const FujairahCities = () => {

    const detailsModelRef = useRef(null);
    const createEventRef = useRef(null)

    const openDetailsModal = (event) => {
        setSelectedCity(event)
        if (detailsModelRef.current) {
            detailsModelRef.current.showModal();
        }
    };

    const openCreateEventModal = () => {
        if (createEventRef.current) {
            createEventRef.current.showModal();
        }
    };

    const [citiesData, setCitiesData] = useState([])
    const [apiBaseUrl, setApiBaseUrl] = useState(axios.defaults.baseURL)
    const [selectedCity, setSelectedCity] = useState([])


    const gelAllCities = () => {
        axios.get(`cities/infos`)
            .then(res => {
                setCitiesData(res.data)
                console.log(res.data)
            })
            .catch(error => {
                console.log(error)
                Swal.fire(
                    "Error!",
                    `${error.response.data.message}`,
                    "error"
                )
            })
    }

    const closeCreateEventModal = () => {
        if (createEventRef.current) {
            createEventRef.current.close();
        }
    };

    useEffect(() => {
        gelAllCities();
        // console.log(axios.defaults.baseURL)
    }, [])

    const deleteEvent = (eventId) => {
        const token = localStorage.getItem("token")
        axios.delete(`cities/delete/${eventId}/?token=${token}`)
            .then(res => {
                console.log(res.data);
                gelAllCities()
            })
            .catch(err => {
                Swal.fire(
                    "Error!",
                    `${err.response.data.message}`,
                    "error"
                )
            })
    }

    return (
        <div className=' flex flex-col items-center w-screen py-10 space-y-10'>
            <div className=' flex flex-row w-10/12 items-center p-4 bg-[#DCC3B1] rounded-xl '>
                <div className=' w-full'>
                    <p className=' text-xl font-semibold capitalize'>Fujairah Cities List</p>
                </div>
                <div>
                    <button className='btn bg-white' onClick={() => { openCreateEventModal() }} >Create City</button>
                    <CreateCityModal raf={createEventRef} getData={gelAllCities} closeModal={closeCreateEventModal} />
                </div>
            </div>
            <div className="overflow-x-auto w-10/12">
                <table className="table table-zebra">
                    <thead>
                        <tr>
                            <th></th>
                            <th>City Name</th>
                            <th>City Description</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            citiesData.map((city) => (
                                <tr key={city.id}>
                                    <th>{city.id}</th>
                                    <td>{city.name}</td>
                                    <td className=' w-6/12'>{(city.description).slice(0, 250) + "..."}</td>
                                    <td>
                                        <div className=' flex flex-row space-x-3' >
                                            <div className='tooltip hover:cursor-pointer' data-tip="City Details" onClick={() => { openDetailsModal(city) }}>
                                                <HiInformationCircle size={24} color='#3f85ee' className=' bg-[#3f85ee69] rounded-md' />
                                                <DetailsModel raf={detailsModelRef} data={selectedCity} url={apiBaseUrl.replace('/api/v1', '')} />
                                            </div>
                                            <div className='tooltip hover:cursor-pointer' data-tip="Delete City" onClick={() => { deleteEvent(city.id) }}>
                                                <TiCancel size={24} color='#8E4F4F' className=' bg-[#8E4F4F69] rounded-md' />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default FujairahCities


export const DetailsModel = ({ url, data, raf }) => {

    return (
        <dialog className="modal" ref={raf}>
            <form method="dialog" className="modal-box w-11/12 max-w-5xl items-start">
                <div className=' grid grid-cols-2 gap-5'>
                    <div className='w-full flex flex-col space-y-2'>
                        {
                            data.bodyPicture !== null ?
                                <img src={url + data.bodyPicture} className=' object-cover w-full h-full' alt="" />
                                :
                                null
                        }
                        <label htmlFor="bodyPicture" className=' font-semibold text-lg'>Body Picture</label>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        {
                            data.heroPicture !== null ?
                                <img src={url + data.heroPicture} className=' object-cover w-full h-full' alt="" />
                                :
                                null
                        }
                        <label htmlFor="heroPicture" className=' font-semibold text-lg'>Hero Picture</label>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        {
                            data.outsidePicture !== null ?
                                <img src={url + data.outsidePicture} className=' object-cover w-full h-full' alt="" />
                                :
                                null
                        }
                        <label htmlFor="outsidePicture" className=' font-semibold text-lg'>Outside Picture</label>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventName" className=' font-semibold text-lg'>City Name</label>
                        <div id='eventName' type="text" className='p-3 bg-[#A55B2730] rounded-md text-left px-7'>
                            {data.name ? data.name : "Empty"}
                        </div>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventDescription" className=' font-semibold text-lg'>City Name (arabic)</label>
                        <div id='eventDescription' className='p-3 bg-[#A55B2730] rounded-md text-right px-7'>
                            {data.nameAr ? data.nameAr : "Empty"}
                        </div>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventName" className=' font-semibold text-lg'>Location Latitude</label>
                        <div id='eventName' type="text" className='p-3 bg-[#A55B2730] rounded-md text-left px-7'>
                            {data.locationLatitude ? data.locationLatitude : "Empty"}
                        </div>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventDescription" className=' font-semibold text-lg'>Location Longitude</label>
                        <div id='eventDescription' className='p-3 bg-[#A55B2730] rounded-md text-left px-7'>
                            {data.locationLongitude ? data.locationLongitude : "Empty"}
                        </div>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventName" className=' font-semibold text-lg'>Short Description</label>
                        <div id='eventName' type="text" className='p-3 bg-[#A55B2730] rounded-md text-left px-7'>
                            {data.shortDescription ? data.shortDescription : "Empty"}
                        </div>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventDescription" className=' font-semibold text-lg'>Short Description (arabic)</label>
                        <div id='eventDescription' className='p-3 bg-[#A55B2730] rounded-md text-right px-7'>
                            {data.shortDescriptionAr ? data.shortDescriptionAr : "Empty"}
                        </div>
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventName" className=' font-semibold text-lg'>Description</label>
                        <div id='eventName' type="text" className='p-3 bg-[#A55B2730] rounded-md text-left px-7'>
                            {data.description}
                        </div>
                    </div>
                    {/* <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventDescription" className=' font-semibold text-lg'>Description (arabic)</label>
                        <div id='eventDescription' className='p-3 bg-[#A55B2730] rounded-md px-7 min-h-16' dir='rtl' >
                            {data.descriptionAr}
                        </div>
                    </div> */}
                </div>
                <div className="modal-action">
                    {/* if there is a button, it will close the modal */}
                    <button className="btn bg-[#8E4F4F69] text-[#8E4F4F]">Close</button>
                </div>
            </form>
        </dialog>
    )
}

export const CreateCityModal = ({ raf, getData, closeModal }) => {

    const [Name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [shortDescription, setShortDescription] = useState('')
    const [NameAr, setNameAr] = useState('')
    const [descriptionAr, setDescriptionAr] = useState('')
    const [shortDescriptionAr, setShortDescriptionAr] = useState('')
    const [bodyPictureLink, setBodyPictureLink] = useState("")
    const [heroPicureLink, setHeroPicureLink] = useState("")
    const [outsidePictureLink, setOutsidePictureLink] = useState("")
    const [bodyPicture, setBodyPicture] = useState(null)
    const [heroPicture, setHeroPicture] = useState(null)
    const [outsidePicture, setOutsidePicture] = useState(null)
    const [featuresData, setFeaturesData] = useState({
        feature1: '',
        feature2: '',
        feature3: '',
        feature4: '',
    });
    const [featuresDataAr, setFeaturesDataAr] = useState({
        feature1: '',
        feature2: '',
        feature3: '',
        feature4: '',
    });
    const [longitude, setLongitude] = useState("")
    const [latitude, setLatitude] = useState("")

    // Function to update the state when an input field changes
    const updateFeatures = (e) => {
        const { id, value } = e.target;
        setFeaturesData({
            ...featuresData,
            [id]: value,
        });
    };

    const updateFeaturesAr = (e) => {
        const { id, value } = e.target;
        setFeaturesDataAr({
            ...featuresDataAr,
            [id]: value,
        });
    };

    const updateData = (e, state) => {
        state(e.target.value)
    }

    const uploadVideo = async (file, setLinkState) => {
        const formData = new FormData();
        formData.append('userFile', file); // Use the same field name as in your Express route

        try {
            const response = await axios.post('upload/upload_file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
                },
            });
            setLinkState(response.data)

            console.log('File uploaded successfully:', response.data);
        } catch (error) {
            Swal.fire(
                "Error!",
                `${error}`,
                "error"
            )
        }
    }

    const createEvent = () => {
        const datas = {
            bodyPicture: bodyPictureLink,
            heroPicture: heroPicureLink,
            outsidePicture: outsidePictureLink,
            name: Name,
            description: description,
            shortDescription: shortDescription,
            featuresList: featuresData,
            nameAr: NameAr,
            descriptionAr: descriptionAr,
            shortDescriptionAr: shortDescriptionAr,
            featuresListAr: featuresDataAr,
            locationLatitude: latitude,
            locationLongitude: longitude
        }
        const token = localStorage.getItem("token")

        axios.post(`cities/create/?token=${token}`, datas)
            .then(res => {
                console.log(res.data)
                closeModal()
                Swal.fire(
                    "Success!",
                    "City successfully created",
                    "success"
                )
                setBodyPictureLink("")
                setBodyPicture(null)
                setHeroPicureLink("")
                setHeroPicture(null)
                setHeroPicureLink("")
                setOutsidePicture(null)
                setOutsidePictureLink("")
                setShortDescription("")
                getData()

            })
            .catch(err => {
                Swal.fire(
                    "Error!",
                    `${err.response.data.message}`,
                    "error"
                )
            })
    }

    return (
        <dialog className="modal" ref={raf}>
            <form method="dialog" className="modal-box w-11/12 max-w-5xl items-start space-y-5">
                <div className=' grid grid-cols-2 gap-5'>
                    <div className='w-full flex flex-col space-y-2'>
                        {
                            bodyPicture !== null ?
                                <img src={URL.createObjectURL(bodyPicture)} className=' object-cover w-full h-full' alt="" />
                                :
                                null
                        }
                        <label htmlFor="bodyPicture" className=' font-semibold text-lg'>Body Picture</label>
                        <input id='bodyPicture' onChange={(e) => { setBodyPicture(e.target.files[0]); uploadVideo(e.target.files[0], setBodyPictureLink) }} type="file" className="file-input file-input-bordered w-full max-w-xs" />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        {
                            heroPicture !== null ?
                                <img src={URL.createObjectURL(heroPicture)} className=' object-cover w-full h-full' alt="" />
                                :
                                null
                        }
                        <label htmlFor="heroPicture" className=' font-semibold text-lg'>Hero Picture</label>
                        <input id='heroPicture' onChange={(e) => { setHeroPicture(e.target.files[0]); uploadVideo(e.target.files[0], setHeroPicureLink) }} type="file" className="file-input file-input-bordered w-full max-w-xs" />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        {
                            outsidePicture !== null ?
                                <img src={URL.createObjectURL(outsidePicture)} className=' object-cover w-full h-full' alt="" />
                                :
                                null
                        }
                        <label htmlFor="heroPicture" className=' font-semibold text-lg'>Display Picture</label>
                        <input id='heroPicture' onChange={(e) => { setOutsidePicture(e.target.files[0]); uploadVideo(e.target.files[0], setOutsidePictureLink) }} type="file" className="file-input file-input-bordered w-full max-w-xs" />
                    </div>
                    <div className='w-full flex flex-col space-y-2'></div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventName" className=' font-semibold text-lg'>Location Longitude</label>
                        <input id='eventName' type="text" value={longitude} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setLongitude) }} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventName" className=' font-semibold text-lg'>Location Latitude</label>
                        <input id='eventName' type="text" value={latitude} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setLatitude) }} />
                    </div>
                </div>
                <h3 className='text-2xl font-semibold text-left'>Infos (English)</h3>
                <div className=' divider'></div>
                <div className=' grid grid-cols-2 gap-5'>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventName" className=' font-semibold text-lg'>City Name</label>
                        <input id='eventName' type="text" value={Name} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setName) }} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'></div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="feature1" className=' font-semibold text-lg'>Feature 1</label>
                        <input id='feature1' type="text" className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={updateFeatures} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="feature2" className=' font-semibold text-lg'>Feature 2</label>
                        <input id='feature2' type="text" className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={updateFeatures} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="feature3" className=' font-semibold text-lg'>Feature 3</label>
                        <input id='feature3' type="text" className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={updateFeatures} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="feature4" className=' font-semibold text-lg'>Feature 4</label>
                        <input id='feature4' type="text" className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={updateFeatures} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventDescription" className=' font-semibold text-lg'>Hero Description (Short Description)</label>
                        <textarea maxLength={250} id='eventDescription' value={shortDescription} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setShortDescription) }} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventDescription" className=' font-semibold text-lg'>City Description</label>
                        <textarea id='eventDescription' value={description} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setDescription) }} />
                    </div>
                </div>
                <h3 className='text-2xl font-semibold text-left'>Infos (Arabic)</h3>
                <div className=' divider'></div>
                <div className=' grid grid-cols-2 gap-5'>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventName" className=' font-semibold text-lg'>City Name</label>
                        <input id='eventName' type="text" value={NameAr} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setNameAr) }} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'></div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="feature1" className=' font-semibold text-lg'>Feature 1</label>
                        <input id='feature1' type="text" className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={updateFeaturesAr} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="feature2" className=' font-semibold text-lg'>Feature 2</label>
                        <input id='feature2' type="text" className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={updateFeaturesAr} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="feature3" className=' font-semibold text-lg'>Feature 3</label>
                        <input id='feature3' type="text" className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={updateFeaturesAr} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="feature4" className=' font-semibold text-lg'>Feature 4</label>
                        <input id='feature4' type="text" className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={updateFeaturesAr} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventDescription" className=' font-semibold text-lg'>Hero Description (Short Description)</label>
                        <textarea maxLength={250} id='eventDescription' value={shortDescriptionAr} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setShortDescriptionAr) }} />
                    </div>
                    <div className='w-full flex flex-col space-y-2'>
                        <label htmlFor="eventDescription" className=' font-semibold text-lg'>City Description</label>
                        <textarea id='eventDescription' value={descriptionAr} className='p-3 bg-[#A55B2730] rounded-md text-left px-7' onChange={(e) => { updateData(e, setDescriptionAr) }} />
                    </div>
                </div>
                <div className="modal-action">
                    {/* if there is a button, it will close the modal */}
                    {
                        heroPicureLink && bodyPictureLink && outsidePictureLink ?
                            <div className="btn bg-[#4f8e5469] text-[#4f8e54]" onClick={() => { createEvent() }}>Create</div>
                            : null
                    }
                    <button className="btn bg-[#8E4F4F69] text-[#8E4F4F]">Close</button>
                </div>
            </form>
        </dialog>
    )
}

export const TimeRangeInput = ({ handleStartTimeChange, handleEndTimeChange }) => {

    return (
        <div className="bg-[#A55B2730] p-3 rounded-md shadow-md flex flex-row justify-evenly">
            <div className=' flex flex-col'>
                <label className="block mb-2 font-semibold">Start Time:</label>
                <input
                    type="time"
                    // value={startTime}
                    onChange={handleStartTimeChange}
                    className="border rounded-md p-2 mb-4"
                />
            </div>
            <div className=' flex flex-col'>
                <label className="block mb-2 font-semibold">End Time:</label>
                <input
                    type="time"
                    // value={endTime}
                    onChange={handleEndTimeChange}
                    className="border rounded-md p-2"
                />
            </div>
        </div>
    );
}