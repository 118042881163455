import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react'

const DenyPopUp = ({ showPopUp, onClose, type, permitId, refreshData }) => {

    const [showModal, setShowModal] = useState(showPopUp);
    const [reasons, setReasons] = useState([])
    const [inputData, setInputData] = useState("")

    useEffect(() => {
        setShowModal(showPopUp);
    }, [showPopUp]);

    const closeModal = () => {
        setShowModal(false);
        if (onClose) {
            onClose();
        }
        setReasons([])
    };

    const insertData = () => {
        reasons.push(inputData);
        console.log(reasons)
        setInputData("")
    }

    const removeItemByIndex = (index) => {
        const newArray = [...reasons];
        newArray.splice(index, 1);

        setReasons(newArray)
    }

    const sendEmail = () => {
        const token = localStorage.getItem("token")
        if (type === "One Day") {
            axios.put(`permit/day/update/${permitId}/?token=${token}`, { documents: reasons })
                .then(res => {
                    console.log(res.data)
                    closeModal()
                    setReasons([])
                    refreshData()
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            axios.put(`permit/year/update/${permitId}/?token=${token}`, { documents: reasons })
                .then(res => {
                    console.log(res.data)
                    closeModal()
                    setReasons([])
                    refreshData()
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    return (
        <React.Fragment>
            <Transition appear show={showModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900 pb-4"
                                    >
                                        Provide reasons or documents
                                    </Dialog.Title>
                                    <div className=' flex flex-col w-full space-y-10'>
                                        <div className='flex flex-row w-full relative justify-between'>
                                            <input value={inputData} onChange={(e) => { setInputData(e.target.value) }} className='p-3 rounded-lg w-10/12 border border-gray-500'></input>
                                            <button onClick={() => { inputData === "" ? alert("Input field can't be empty") : insertData() }} className='p-3 bg-[#d6a97b71] rounded-lg px-6 font-medium text-lg'>Insert</button>
                                        </div>
                                        <ul className=' list-disc space-y-4 px-10'>
                                            {
                                                reasons.length > 0 &&
                                                reasons.map((reason, index) => (
                                                    <div key={index} className=' flex flex-row space-x-4 items-center w-6/12'>
                                                        <li className=' w-8/12'>{reason}</li>
                                                        <button onClick={() => { removeItemByIndex(index) }} className='p-1 bg-[#CFA891] rounded-lg px-6 font-medium text-lg'>Delete</button>
                                                    </div>
                                                ))
                                            }
                                        </ul>

                                        {
                                            reasons.length > 0 &&
                                            <div className=' flex flex-row'>
                                                <button onClick={() => { sendEmail() }} className='p-3 bg-[#d6a97b71] mx-auto rounded-lg px-6 font-medium text-lg'>Proceed to Deny</button>
                                            </div>
                                        }
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </React.Fragment>
    )
}

export default DenyPopUp