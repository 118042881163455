import { Dialog, Transition } from '@headlessui/react'
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react'

const InfoPopUp = ({ data, closeModal, showPopUp }) => {

    const [showModal, setShowModal] = useState(showPopUp);

    useEffect(() => {
        setShowModal(showPopUp);
    }, [showPopUp]);

    let authorities;

    if (data.issuingAuthority) {
        const authoritiesArray = JSON.parse(data.issuingAuthority)
        const authorityTitles = (authoritiesArray).map(activity => activity.title);
        authorities = authorityTitles.join(', ')
        console.log(data)
    } else {
        console.error('Data or event is null or undefined');
    }

    return (
        <Fragment>
            <Transition appear show={showModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    {/* <div className="fixed inset-0 overflow-y-auto"> */}
                                        <h3 className='text-2xl font-semibold text-left mb-4'>Company Informations</h3>
                                        <div className=' grid grid-cols-3 gap-5'>
                                            <div className=' flex flex-col space-y-2'>
                                                <h3 className=' text-left'>Name</h3>
                                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                    {data.name}
                                                </div>
                                            </div>
                                            <div className=' flex flex-col space-y-2'>
                                                <h3 className=' text-left'>License Number</h3>
                                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                    {data.licenseNumber}
                                                </div>
                                            </div>
                                            <div className=' flex flex-col space-y-2'>
                                                <h3 className=' text-left'>Issuing Authority</h3>
                                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                    {authorities}
                                                </div>
                                            </div>
                                            <div className=' flex flex-col space-y-2'>
                                                <h3 className=' text-left'>Contact Number</h3>
                                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                    {data.contactNumber}
                                                </div>
                                            </div>
                                            <div className=' flex flex-col space-y-2'>
                                                <h3 className=' text-left'>Contact Email</h3>
                                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                    {data.contactEmail}
                                                </div>
                                            </div>
                                            <div className=' flex flex-col space-y-2'>
                                                <h3 className=' text-left'>Expiration Date</h3>
                                                <div className=' p-4 bg-[#A55B2730] rounded-md w-10/12 text-left px-7'>
                                                    {moment(data.expirationDate).format("MMMM Do YYYY")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-action">
                                            <button onClick={() => { closeModal() }} className="btn bg-[#8E4F4F69] text-[#8E4F4F]">Close</button>
                                        </div>
                                    {/* </div> */}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </Fragment>
    )
}

export default InfoPopUp