import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import Swal from 'sweetalert2'

const Login = () => {

  const history = useNavigate()

  const [Email, setEmail] = useState("")
  const [Password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [loginForm, setLoginForm] = useState(false)
  const [adminLogin, setAdminLogin] = useState(false)

  const login = (e) => {
    e.preventDefault();
    const data = {
      email: Email,
      password: Password
    }
    axios.post("admin/login", data)
      .then(res => {
        const { token, id, position } = res.data
        localStorage.setItem("token", token)
        localStorage.setItem("admin_id", id)
        localStorage.setItem("position", position)
        Swal.fire({
          title: "Success",
          text: `Hi, ${res.data.name} you'll be redirected to Dashboard`,
          icon: "success",
          timer: 1200
        })
        setTimeout(() => {
          history("/", { replace: true })
        }, 1200);
      })
      .catch(err => {
        Swal.fire(
          "Error!",
          `${err.response.data.message}`,
          "error"
        )
      })
  }

  return (
    <React.Fragment>
      <section className=' flex flex-row h-[100vh] w-full justify-center '>
        {/* <a href="https://api.fujairahadventures.ae/auth/google">test request</a> */}
        <div className='w-4/12 bg-white justify-center flex flex-col relative'>
          <div className='flex flex-col mx-auto w-10/12 space-y-14'>
            <div className=' space-y-5'>
              <p className='text-3xl font-medium text-[#5A5A5A]'>Welcome <span className='font-semibold text-[#A55B27]'>Back</span></p>
              {/* <p className=' text-lg text-[#5A5A5A] font-medium '>{loginForm === true && (adminLogin ? 'Super Admin Dashboard' : 'Admin Dashboard')}</p> */}
            </div>
            <div>
              <form onSubmit={login}>
                <div className='space-y-4'>
                  <div className=' flex flex-col space-y-4'>
                    <label htmlFor="email" className=' font-medium text-[#5A5A5A]'>Email</label>
                    <input type="text" id='email' placeholder='example@admin.com'
                      onChange={(e) => { setEmail(e.target.value) }}
                      value={Email}
                      className='p-2 border-2 text-lg rounded-lg border-gray-600 focus:border-[#A55B27] focus:ring-1 focus:ring-[#A55B27]'
                    />
                  </div>
                  <div className=' flex flex-col space-y-4'>
                    <label htmlFor="password">Password</label>
                    <input id='password' placeholder='•••••••••••'
                      onChange={(e) => { setPassword(e.target.value) }}
                      type={showPassword ? "text" : "password"}
                      value={Password}
                      onMouseEnter={() => { setShowPassword(true) }}
                      onMouseLeave={() => { setShowPassword(false) }}
                      className='p-2 border-2 text-lg rounded-lg border-gray-600 focus:border-[#A55B27] focus:ring-1 focus:ring-[#A55B27]'
                    />
                  </div>
                </div>
                <button type='submit' className=' mt-4 w-full text-white bg-[#A55B27] p-3 rounded-lg text-lg font-medium shadow-lg drop-shadow-sn shadow-[#a55b275d] '>Sign in</button>
              </form>
              {/* <div className=' flex flex-col text-start text-white space-y-4'>
                  <button
                    type='button'
                    onClick={() => { setLoginForm(false); setEmail(''); setPassword('') }}
                    className='p-3 rounded-lg font-bold text-black hover:scale-105 transition duration-300 text-center'>
                    Change Positon ?
                  </button>
                </div> */}
            </div>
          </div>
        </div>
        <div className='relative w-8/12 bg-[#A55B27]'>
          <img src="/images/logo.svg" className=' absolute top-5 left-5' alt="" />
          <img src={"/images/loginBG.png"} alt="" className=' absolute w-full top-0 z-50' />
        </div>

      </section>
    </React.Fragment>
  )
}

export default Login